import React from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/gatsby'
import AuthProvider from './src/context/authProvider'
import { parseQueryString } from './src/utils/generic'
import { isGoogleOrganic } from './src/utils/googleOrganic'
import { setCookie, getCookie, cookiePrefix } from './src/utils/cookies'
import storageService from './src/services/storageService'
import { EnvConfig } from './src/config/EnvConfig'

import packageJson from './package.json'
import { TranslationProvider } from './src/context/translationProvider'
import { navigate } from 'gatsby'
import { PopupsProvider } from './src/context/popupsProvider'
import { DrawerProvider } from './src/context/paymentDrawerProvider'
import { PaymentsProvider } from './src/context/paymentProvider'
import { CreateBankAccountProvider } from './src/context/createBankAccountProvider'

import { addCurrentPage } from './src/utils/historyFunctions'

import { getBrowserLanguage } from './src/utils/getBrowserLanguage'
import { MEDIA_MOUNT_PPC } from './src/utils/constants'
import { ModalProvider } from './src/context/modalProvider'
import { queryStringToObject } from './src/helpers/stringHelper'
import {
  isMobileApp,
  updateMobileLocation,
} from './src/utils/mobileAppFunctionality'
import './src/assets/fonts/fonts.css'
import { getWebsiteUrl, useLocalePathPrefix } from './src/utils/getWebsiteUrl'
import { RealityCheckProvider } from './src/context/realityCheckProvider'

import 'radar-sdk-js/dist/radar.css'
import { EventTrackingProvider } from './src/context/eventTrackingProvider'
import { GrowthBookProvider } from './src/context/GrowthBookProvider'

const env = process.env.GATSBY_ENV
const locale = process.env.GATSBY_INTL_LOCALE
const nodeEnv = process.env.NODE_ENV
const sentryDSNs = JSON.parse(process.env.GATSBY_SENTRY_DNS_URL || "[]")
const websiteURL = getWebsiteUrl()

// Sentry Configuration Starts
if (sentryDSNs.length === 0) {
  console.error("No DSNs configured for Sentry!");
}

// Primary Sentry client setup (first DSN in the array)
const primaryDSN = sentryDSNs[0];
const secondaryDSNs = sentryDSNs.slice(1); // All additional DSNs

const integrations = Sentry.getDefaultIntegrations({}).filter(
  (defaultIntegration) => {
    return !["BrowserApiErrors", "Breadcrumbs", "GlobalHandlers"].includes(
      defaultIntegration.name,
    );
  },
);

// Function to generate sentryOptions
const createSentryOptions = (dsn, isPrimary = false) => {
  const sentryOptions = {
    dsn,
    integrations,
    transport: Sentry.makeFetchTransport,
    stackParser: Sentry.defaultStackParser,  
    enabled: (() => ['production'].indexOf(nodeEnv) !== -1)(),
    whitelistUrls: [process.env.GATSBY_SENTRY_WHITELIST_URL],
    tracesSampleRate: 0.1,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    environment: `${env}${isMobileApp() ? '-mobile' : ''}`,
    sampleRate: 0.5,
    autoSessionTracking: true,
    attachStacktrace: true,
    ignoreErrors: [
      'Request failed with status code 401',
      /^Cannot read properties of undefined (reading 'status')$/,
      '_objectWithoutPropertiesLoose',
    ],
  };

  // Add replication logic for the primary client
  if (isPrimary && secondaryDSNs.length > 0) {
    sentryOptions.beforeSend = (event) => {
      // Forward event to all secondary Sentry clients
      secondaryClients.forEach((client) => {
        client.captureEvent(event);
      });

      // Return the event for the primary client
      return event;
    };
  }

  // Support manual override for tracking everything
  if (localStorage?.getItem?.('forceSentryToTrack')) {
    delete sentryOptions.enabled;
    delete sentryOptions.whitelistUrls;
    sentryOptions.tracesSampleRate = 1.0;
  }

  return sentryOptions;
};

// Step 1: Initialize Secondary Sentry Clients
const secondaryClients = secondaryDSNs.map((dsn) => {
  const secondaryOptions = createSentryOptions(dsn);
  return new Sentry.BrowserClient(secondaryOptions);
});

// Step 2: Initialize Primary Sentry Client
const primaryOptions = createSentryOptions(primaryDSN, true);
Sentry.init(primaryOptions); // Primary instance initialized via Sentry.init()
// Sentry Configuration Ends

export const wrapRootElement = ({ element }) => {
  return (
    <TranslationProvider>
      <GrowthBookProvider>
        <EventTrackingProvider>
          <AuthProvider>
            <PaymentsProvider>
              <DrawerProvider>
                <ModalProvider>
                  <RealityCheckProvider>
                    <PopupsProvider>
                      <CreateBankAccountProvider>
                        {element}
                      </CreateBankAccountProvider>
                    </PopupsProvider>
                  </RealityCheckProvider>
                </ModalProvider>
              </DrawerProvider>
            </PaymentsProvider>
          </AuthProvider>
        </EventTrackingProvider>
      </GrowthBookProvider>
    </TranslationProvider>
  )
}

export const onRouteUpdate = ({ location }) => {
  addCurrentPage()

  window?.scrollTo(0, 0)

  const params = parseQueryString(location.search)
  if (params.btag) {
    setCookie('btag', params.btag)
  }
  if (params.clickid) {
    setCookie('clickid', params.clickid)
  }
  if (params?.myafftkn) {
    if (!location.pathname.includes('clever-advertising')) {
      setCookie(`${cookiePrefix}affiliateToken`, params?.myafftkn, 15)
      setCookie(
        `${cookiePrefix}affiliateTokenCreationDate`,
        new Date().toISOString(),
        15
      )
    }
  }
  if (params?.promoCode) {
    setCookie(`${cookiePrefix}promoCode`, params?.promoCode)
  }

  if (params?.partner === MEDIA_MOUNT_PPC && params?.myafftkn) {
    setCookie(`${cookiePrefix}PPCToken`, params?.myafftkn, 365)
  }

  if (params?.caToken) {
    setCookie(`${cookiePrefix}caToken`, params?.caToken)
    setCookie(`${cookiePrefix}caTokenCreationDate`, new Date().toISOString())
  }

  if (params?.availableFile) {
    setCookie(`${cookiePrefix}availableFile`, params?.availableFile)
  }

  if (
    location?.pathname.indexOf('login') < 0 &&
    location?.pathname.indexOf('register') < 0 &&
    location?.pathname.indexOf('registro') < 0
  ) {
    storageService.setValue('lastVisitedPage', location.pathname)
  }

  const googleOrganic = getCookie(`${cookiePrefix}googleOrganic`)
  if (
    googleOrganic !== EnvConfig.GATSBY_MY_AFF_BRANDED &&
    googleOrganic !== EnvConfig.GATSBY_MY_AFF_ORGANIC
  ) {
    setCookie(`${cookiePrefix}googleOrganic`, isGoogleOrganic(), 15)
  }

  checkAppVersion()
  verifyLobby()
  handleRedirection({ location, params })
  handleDepositRedirection()
  updateMobileLocation(location)
  updatePagePath({ location })
}

// TODO: Re-visit this method
export const onClientEntry = () => {
  window.dataLayer = window.dataLayer || []
  appendThirdPartyScripts()

  const language = getBrowserLanguage()

  if (!getCookie(`${cookiePrefix}market`))
    setCookie(`${cookiePrefix}market`, language, 365)

  if (typeof window.IntersectionObserver === 'undefined') {
    require('intersection-observer')
  }

  window?.dataLayer?.push({
    event: 'app_version',
    version: packageJson.version,
  })
}

const scripts = {
  smartico: {
    __html: `(function(d, r, b, h, s){
      h = d.getElementsByTagName('head')[0];
      s = d.createElement('script');
      s.onload = b;
      s.onerror = function(event) {
        const e = new CustomEvent('smarticoError', event);
        document.dispatchEvent(e);
    };
        s.src = r;
        h.appendChild(s);
    })(document, '${EnvConfig.GATSBY_SMARTICO_SCRIPT_URL}', function() {
        const initCallback = () => {
          window._smartico.off('init', initCallback)
          window.dispatchEvent(new CustomEvent("SMARTICO_LOADED"))
        }
        _smartico.on('init', initCallback)
        _smartico.init('${EnvConfig.GATSBY_SMARTICO_LABEL_KEY}', { brand_key: '${EnvConfig.GATSBY_SMARTICO_BRAND_KEY}' });
    });`,
  },
}

const addSmarticoErrorListener = () => {
  document.addEventListener('smarticoError', (event) => {
    const userId = storageService?.getValue('user')?.id || 'unknown'

    const errorDetails = {
      message: 'Smartico script failed to load for user with id: ' + userId,
      errorCode: event?.code || 'UNKNOWN_ERROR_CODE',
      errorMessage: event?.message || 'No error message available',
    }
    Sentry.captureException(new Error(JSON.stringify(errorDetails)))
  })
}

const appendThirdPartyScripts = () => {
  addSmarticoErrorListener()
  appendScript(scripts.smartico.__html)
}

const appendScript = (scriptInnerHTML) => {
  const scriptElement = document.createElement('script')
  scriptElement.innerHTML = scriptInnerHTML
  document.head.appendChild(scriptElement)
}

const checkAppVersion = () => {
  try {
    axios
      .get(`${websiteURL}meta.json?v=${new Date().getTime()}`)
      .then((meta) => {
        if (meta.data.versionHash) {
          const latestVersion = meta.data.versionHash
          const currentVersion = parseInt(
            storageService.getValue('APP_VERSION')
          )

          if (!isNaN(currentVersion) && latestVersion !== currentVersion) {
            refreshCacheAndReload(latestVersion)
          } else {
            storageService.setValue('APP_VERSION', latestVersion)
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  } catch (ex) {
    console.error(ex)
  }
}

const refreshCacheAndReload = (latestVersion) => {
  storageService.setValue('APP_VERSION', latestVersion)

  if (caches) {
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name)
      }
    })
  }
  window.location.reload(true)
}

const verifyLobby = () => {
  if (env === 'dev' || env === 'stage') return
  if (!useLocalePathPrefix) return
  const lobby = storageService.getValue('lobby')

  if (!lobby?.includes(`/${locale}/`)) {
    storageService?.removeValue('lobby')
    storageService?.removeValue('gameLobby')
  }
}

// TODO: This function is now depricated, we should try and remove it
// once we have assured that nobody is using redirect=login anymore
const handleRedirection = ({ location, params }) => {
  const isLoggedIn = !!storageService.getValue('user')
  if (!isLoggedIn) {
    const redirect = params?.redirect
    if (redirect === 'login') {
      const loginSlug = storageService.getValue('loginSlug') || 'login'
      navigate(`/${loginSlug}`, { state: { redirectURL: location?.pathname } })
    }
  }
}

const handleDepositRedirection = () => {
  const urlParams = queryStringToObject(window?.location?.search)
  if (urlParams?.action === 'deposit' && !storageService.getValue('user')) {
    navigate(`/${storageService.getValue('loginSlug')}`, {
      state: {
        redirectURL: `${window?.location?.pathname}${window?.location?.search}`,
      },
    })
  }
}

const updatePagePath = ({ location }) => {
  if (window && window.pagePath !== location.pathname) {
    window.pagePath = location.pathname
  }
}
