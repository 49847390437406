import React from 'react'

import { FooterMenu } from '../../molecules/footerMenu'
import { FooterImagesSections } from '../../molecules/footerImagesSections'
import { FooterCookie } from '../../molecules/footerCookie'
import { FooterLogo } from '../../atoms/footerLogo'
import { FooterLicense } from '../../atoms/footerLicense'
import { ResponsibleGambling } from '../../atoms/responsibleGaming'

import { FooterLinksImages, FooterContainer } from './styles'
import { useGroowthbookLocaleFeature } from '../../../hooks/useGrowthbook'
import { FooterVersion } from '../../molecules/FooterVersion'

const Footer = () => {
  const isBottomBarEnabled = useGroowthbookLocaleFeature(
    'fe_igp_bottom_bar_navigation'
  )

  return (
    <FooterContainer $hasExtraPaddingBottom={isBottomBarEnabled}>
      <FooterLinksImages>
        <FooterMenu />
        <FooterImagesSections />
      </FooterLinksImages>
      <ResponsibleGambling />
      <FooterCookie />
      <FooterLicense />
      <FooterLogo />
      <FooterVersion />
    </FooterContainer>
  )
}

export { Footer }
