import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../../context/translationProvider'
import accessDeniedImg from './../../../images/access-denied.svg'
import { If } from './../../atoms/if'
import { Modal } from '../../atoms/modal'
import useSlugs from '../../../hooks/useSlugs'
import { 
  StyledImage,
  StyledLi,
  StyledText,
  StyledTitle,
  StyledUl,
  StyledModalWrapper,
  StyledButton
} from './style'
import useModal from '../../../hooks/useModal'
import { navigate } from 'gatsby'

export const RadarErrorModal = (props) => {

  const { radarResponse } = props

  const { translate } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [translatedReasons, setTranslatedReasons] = useState([])
  const [casinoSlug, liveCasinoSlug] = useSlugs(['casino', 'live-casino'])
  const gameSlug = `${casinoSlug}/game/`
  const radarErrorSlugs = [casinoSlug, liveCasinoSlug]
  const { closeModal } = useModal()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer?.push({
        event: 'radar_error'
      })
    }
  }, [])

  useEffect(() => {
    if (!radarResponse || !radarErrorModalEnabled()) return
  
    if (!radarResponse.passed && window.location.href.includes(gameSlug)) {
      navigate(`/${casinoSlug}`)
      return
    }
  
    setIsOpen(!radarResponse.passed)
  
    if (!radarResponse.passed) {
      setTranslatedReasons(getTranslatedReasons(radarResponse?.reasons))
    }
  }, [radarResponse])

  const toggleContactUs = () => {
    if (window.isIntercomOpen) window.Intercom('hide')
    else {
      window.Intercom('show')
    }
  }

  const radarErrorModalEnabled = () => {
    return !window.isIntercomOpen && radarErrorSlugs.some(slug => window.location.href.includes(slug))
  }

  const getTranslatedReason = (reasons) => {
    if (!reasons?.length) return translate('radar.error.generic')
  
    const reasonKey = `radar/error/${reasons[0]}`
    const translatedReason = translate(reasonKey)
  
    return translatedReason === reasonKey ? translate('radar.error.generic') : translatedReason
  }

  const getTranslatedReasons = (reasons) => {
    if (!reasons || !reasons.length) return []
  
    return reasons.map(reason => {
      const translated = translate(`radar.error.${reason}`)
      return translated === `radar/error/${reason}` ? null : translated
    })
  }

  const returnSingleReason = () => (
    <StyledText>
      {getTranslatedReason(radarResponse?.reasons)}{' '}
      <span
        dangerouslySetInnerHTML={{
          __html: translate("radar.error.foundOutMoreSingle"),
        }}
      />
    </StyledText>
  )

  const returnMultipleReasons = () => (
    <>
      <StyledText>{translate(`radar.error.denialReasons`)}</StyledText>
      <StyledUl>
        {translatedReasons.filter(Boolean).map((translated, index) => (
          <StyledLi key={index}>{translated}</StyledLi>
        ))}
        {translatedReasons.includes(null) && (
          <StyledLi>{translate('radar.error.generic')}</StyledLi>
        )}
      </StyledUl>
      <StyledText>
        <span
          dangerouslySetInnerHTML={{
            __html: translate(`radar.error.foundOutMoreMultiple`),
          }}
        />
      </StyledText>
    </>
  );

  return (
    <Modal
      theme="payments"
      title={translate('radar.error.modalHeading')}
      onClose={closeModal}
      isOpen={isOpen}
      hideClose
    >
      <StyledModalWrapper $isMultiple={radarResponse?.reasons?.length > 1}>
        <StyledTitle>{translate('radar.error.modalTitle')}</StyledTitle>
        <StyledImage url={accessDeniedImg}/>
        <If
          condition={radarResponse?.reasons?.length === 1}
          render={() => returnSingleReason()}
          renderElse={() => returnMultipleReasons()}
        />
        <StyledButton onClick={() => toggleContactUs()}>
          {translate('geolocation.contactSupport')}
        </StyledButton>
      </StyledModalWrapper>
    </Modal>
  )
}
