import React from 'react'
import PropTypes from 'prop-types'

import { StyledPromoTag } from './styles'
import { useTranslation } from '../../../context/translationProvider'
import { themeStyles } from '../../../styles/theme'

const Tag = (props) => {
  const { type, theme, text, size } = props
  const { translate } = useTranslation()

  const backgroundColors = {
    // Type Colors
    casino: themeStyles.colors.products.liveCasino.main,
    livecasino: themeStyles.colors.products.casino.main,
    sports: themeStyles.colors.products.sports.main,
    virtualsports: themeStyles.colors.products.virtualSports.main,
    welcome_offer: themeStyles.colors.accentColors.secondary.main,
    opted_in: themeStyles.colors.defaultColors.green[250],
    secondary: themeStyles.colors.accentColors.secondary.main,

    // Theme Colors
    blue: themeStyles.colors.products.sports.main,
    green: themeStyles.colors.accentColors.primary.main,
    grey: themeStyles.colors.lightGreys[1].main,
  }

  const fontColors = {
    // Type Colors
    welcome_offer: themeStyles.colors.black.main,

    // Theme Colors
    secondary: themeStyles.colors.accentColors.secondary.contrast,
    green: themeStyles.colors.black.main,
    grey: themeStyles.colors.lightGreys[1].contrast,
  }

  return (
    <StyledPromoTag
      backgroundColor={backgroundColors[type || theme] || themeStyles[theme]}
      color={fontColors[type || theme] || themeStyles.colors.white.main}
      size={size}
      {...props}
    >
      {text || translate(`promotions.${type}`)}
    </StyledPromoTag>
  )
}

Tag.defaultProps = {
  type: '',
  theme: '',
  fontSize: null,
  fontWeight: null,
  text: null,
  size: null,
}

Tag.propTypes = {
  type: PropTypes.oneOf([
    '',
    'casino',
    'livecasino',
    'sports',
    'virtualsports',
    'welcome_offer',
    'opted_in',
    'secondary',
  ]),
  theme: PropTypes.oneOf([
    '',
    'blue',
    'green',
    'darkGrey5',
    'yellow',
    'secondary',
  ]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  size: PropTypes.string,
}

export { Tag }
