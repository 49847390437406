import { useEffect, useState } from 'react'

const bannerSelector = 'iframe[data-intercom-frame="true"]:not([name="intercom-messenger-frame"])'

export const useBannerPosition = () => {
  const [bannerPosition, setBannerPosition] = useState({
    position: undefined,
    height: undefined
  })

  const handleBannerPosition = () => {
    const bodyStyle = window.getComputedStyle(document.body)
    const bodyMarginTop = parseInt(bodyStyle.marginTop, 10)
    const bannerElement = document.body.querySelector(bannerSelector)

    if (!bannerElement || isNaN(bodyMarginTop)) {
      setBannerPosition({ position: undefined, height: undefined })
      return
    }

    const height = bannerElement.offsetHeight
    const position = bodyMarginTop > 0
      ? 'top'
      : 'bottom'

    setBannerPosition({ position, height })
  }

  useEffect(() => {
    const waitContentChangeAndCallHandler = () => {
      setTimeout(handleBannerPosition, 300)
    }

    const bannerObserver = new MutationObserver(waitContentChangeAndCallHandler)
    bannerObserver.observe(document.body, {
      attributes: true,
      attributeFilter: ['style', 'class']
    })

    return () => bannerObserver.disconnect()
  }, [])

  return bannerPosition
}
