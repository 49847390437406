import React from 'react'
import { Text, Title } from '../../atoms/text'
import { StyledTextWrapper } from './styles'

export const AddBankAccountHeader = ({
  pageTitle,
  pageSubtitle,
}) => {
  return (
    <StyledTextWrapper>
      <Title level={2}>{pageTitle}</Title>

      <Text level={3}>{pageSubtitle}</Text>
    </StyledTextWrapper>
  )
}
