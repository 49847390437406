import React, { useState } from 'react'
import {
  AidCustomerTitle,
  Wrapper,
  AidCustomerDescription,
  AidCustomerImages,
  AidCustomerButton,
  AidCharacterImg,
  AidCustomerContent,
} from './style'
import SimplePopUp from '../../atoms/simplePopUp'
import PropTypes from 'prop-types'
import { Button } from '../../atoms/button'
import SmallCircleSteps from '../../molecules/smallCircleSteps'

const AidCustomerWizard = (props) => {
  const { steps, onLastClick, handleClose } = props
  const [currentStep, setCurrentStep] = useState(0)

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
      return
    }

    if (onLastClick) {
      onLastClick()
      handleClose()
    }

    handleClose()
  }

  const currentStepData = steps[currentStep]

  return (
    <SimplePopUp onClose={handleClose}>
      <Wrapper>
        <AidCustomerContent>
          <AidCustomerTitle>{currentStepData.title}</AidCustomerTitle>
        </AidCustomerContent>
        <AidCustomerImages>
          {currentStepData.images.map((image, index) => (
            <AidCharacterImg
              key={index}
              src={image}
              alt={`Aid Character ${index + 1}`}
            />
          ))}
        </AidCustomerImages>
        <AidCustomerDescription>
          {currentStepData.description}
        </AidCustomerDescription>
        <AidCustomerButton>
          <Button expand onClick={handleNext}>
            {currentStepData.buttonText}
          </Button>
        </AidCustomerButton>
        <SmallCircleSteps
          currentStep={currentStep + 1}
          totalSteps={steps.length}
        />
      </Wrapper>
    </SimplePopUp>
  )
}

AidCustomerWizard.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  onLastClick: PropTypes.func,
  handleClose: PropTypes.func,
}

export default AidCustomerWizard
