import styled from 'styled-components';
import { themeStyles } from '../../../../../styles/theme';

export const StyledWrapper = styled.div`
  > p {
    font-size: 25px;
  }
`;

export const StyledDivider = styled.div`
  margin: 40px 0;
  height: 1px;
  width: 100%;
  background-color: #e1e1e1;
  position: relative;
`

export const StyledDividerLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: ${themeStyles.colors.white.main};
  padding: 0 16px;
  font-size: 21px;
`

export const StyledLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 350px;
`