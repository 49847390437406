import React from 'react'
import PropTypes from 'prop-types'
import SimplePopUp from '../../atoms/simplePopUp'
import { Button } from '../../atoms/button'
import {
  ResultWrapper,
  StyledHeaderIcon,
  VerifiedIcon,
  StyledMainTitle,
  StyledMainDescription,
  StyledButton,
} from './style'
import VerifiedCloseIcon from '../../../images/icon_verified_close.svg'

const ProblemWizard = (props) => {
  const { title, description, buttonText, onClose, onClick } = props
  return (
    <SimplePopUp onClose={onClose}>
      <ResultWrapper>
        <StyledHeaderIcon>
          <VerifiedIcon src={VerifiedCloseIcon} alt="" />
        </StyledHeaderIcon>
        <StyledMainTitle>{title}</StyledMainTitle>
        <StyledMainDescription>{description}</StyledMainDescription>
        <StyledButton>
          <Button expand onClick={onClick}>
            {buttonText}
          </Button>
        </StyledButton>
      </ResultWrapper>
    </SimplePopUp>
  )
}

ProblemWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ProblemWizard
