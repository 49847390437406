import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '../../atoms/modal'
import { Button, If } from '@techmobilt/ui-components'
import { useTranslation } from '../../../context/translationProvider'
import {
  StyledFirstStepContainer,
  StyledFirstStepContent,
  StyledModalContentWrapper,
  StyledVerificationMessage,
  StyledVerificationStatusTitle 
} from './styles'
import { Image } from '../../atoms/image'
import selfieAndIdIcon from '../../../images/icon_selfie_and_id_gray.svg'
import Checkmark from '../../../images/icon_circle_check_green_dark.svg'
import SupportChatIcon from '../../../images/icon_support_chat.svg'
import icon_selfie_and_id_failed from '../../../images/icon_selfie_and_id_red.svg'
import { toast } from '../../atoms'
import { themeStyles } from '../../../styles/theme'
import { AuthContext } from '../../../context/authProvider'
import { isMobile } from '../../../utils/generic'
import { CafIFrame } from '../../molecules/cafIFrame'
import {
  CAF_MESSAGE_CODES,
  FIVE_SECONDS,
  TEN_SECONDS,
  KycStatus,
  THIRTY_SECONDS
} from '../../../utils/constants'
import { getKYCRejectReasons, getKYCVerificationData } from '../../../adapters/kyc'
import storageService from '../../../services/storageService'
import { usePaymentDrawerContext } from '../../../context/paymentDrawerProvider'
import { StyledCheckedIcon } from '../registerContent/styles'
import { useEventTracking } from '../../../context/eventTrackingProvider'
import { InfoBox } from '../../molecules/InfoBox'
import { useReCaptcha } from '../../../hooks/useReCaptcha'
import { getUserMigratableStatus } from '../../../utils/getUserMigratableStatus'
import { useDebounce } from '../../../hooks/useDebounce'
import { Script } from 'gatsby'
import { EnvConfig } from '../../../config/EnvConfig'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

export const KYCVerifyAccountModal = ({ 
  isOpen,
  onClose = () => null,
  onFinish = () => null,
}) => {
  const { open: openDepositDrawer } = usePaymentDrawerContext()
  const { logoutUser, safeSetUser } = useContext(AuthContext)
  const { translate, lang } = useTranslation()
  const [step, setStep] = useState(0)
  const { trackEvent, EVENT_NAMES, EVENT_LOCATIONS } = useEventTracking()
  const [userHasBalance, setUserHasBalance] = useState(false)
  const IS_QR_CODE_FLOW = useFeatureIsOn('fe_igp_caf_qr_code_flow')
  const IS_OLD_BALANCE_ENABLED = 
    useFeatureValue('fe_ipg_old_balance_warning_enabled', false)

  const SHOW_OLD_BALANCE_WARNING = userHasBalance && IS_OLD_BALANCE_ENABLED

  const recaptchaGetUserMigratableStatus = useReCaptcha(
    getUserMigratableStatus, 'IS_MIGRATEABLE'
  )

  const getBalanceStatus = async () => {
    try {
      const user = storageService.getUser()
      const { 
        hasBalance
      } = await recaptchaGetUserMigratableStatus({ 
        email: user.email
      })
      setUserHasBalance(hasBalance)
    } catch (err) {
      console.error(err)
    }
  }

  const debounceGetBalanceStatus = useDebounce(getBalanceStatus, 500)

  useEffect(() => {
    if (isOpen) {
      debounceGetBalanceStatus()
    }
  }, [isOpen])
  const [rejectReason, setRejectReason] = useState(null)

  const toggleContactUs = () => {
    if (window.isIntercomOpen) window.Intercom('hide')
    else {
      window.Intercom('show')
    }
  }

  const checkIsUserActive = async () => {
    await safeSetUser()

    const user = storageService.getUser()
    if (user?.user_status?.name?.toUpperCase() !== 'PENDING') {
      setStep(2)
      return true
    }

    return false
  }

  const createUpdateUserInterval = () => {
    const updateInterval = setInterval(async () => {
      const isUserActive = await checkIsUserActive();
  
      if (isUserActive) {
        clearInterval(updateInterval);
      }
    }, TEN_SECONDS);
  
    return updateInterval;
  };

  useEffect(() => {
    if (!isOpen) return

    const { SCREEN_LOADED, KYC_START, KYC_FAILED } = EVENT_NAMES
    const { REGISTER_VERIFY_ACCOUNT, REGISTER_ACCOUNT_VERIFIED } =
      EVENT_LOCATIONS

    if (step === 0)
      return trackEvent(SCREEN_LOADED, {
        screen_name: REGISTER_VERIFY_ACCOUNT,
      })

    if (step === 1) return trackEvent(KYC_START)

    if (step === 2)
      return trackEvent(SCREEN_LOADED, {
        screen_name: REGISTER_ACCOUNT_VERIFIED,
      })

    if (step === 3) return trackEvent(KYC_FAILED)
  }, [step, isOpen])

  useEffect(() => {
    if (!isOpen || !lang) return
    const registrationCompletedToast 
      = storageService.getValue('registrationCompletedToast')

    const showToast = registrationCompletedToast === 'show'

    if (showToast) {
      toast.success(translate('register.lead.success'))
      storageService.removeValue('registrationCompletedToast')
    }

  }, [isOpen, lang])

  useEffect(() => {
    if (!isOpen) return;
  
    const updateUserInterval = createUpdateUserInterval();
  
    return () => {
      clearInterval(updateUserInterval);
    };
  }, [isOpen]);

  const poolingKYCStatus = async () => {
    const poolingInterval = setInterval(async () => {
      const response = await getKYCVerificationData()
      const status = response.data.identificationAndSelfie?.status || null
      if (status === KycStatus.APPROVED) {
        clearInterval(poolingInterval)
        await safeSetUser()
        setStep(2)
        return
      }

      const KYC_REJECTED = 
        status === KycStatus.REJECTED || status === KycStatus.REPROVED
      
      if (KYC_REJECTED) {
        clearInterval(poolingInterval)
        const { data } = await getKYCVerificationData()
        const { data: rejectReasonData } = await getKYCRejectReasons(data?.identificationAndSelfie?.id)
        
        setRejectReason(rejectReasonData?.[0])
        setStep(3)
        return
      }
    }, FIVE_SECONDS)
  }

  useEffect(() => {
    if (!IS_QR_CODE_FLOW || step !== 1) return

    const POOLING_INTERVAL = isMobile() ? FIVE_SECONDS : THIRTY_SECONDS

    const timeOut = setTimeout(() => {
      poolingKYCStatus()
    }, POOLING_INTERVAL)

    return () => clearTimeout(timeOut)
  }, [IS_QR_CODE_FLOW, step])

  const closeCafIFrame = (status) => {
    if (status !== CAF_MESSAGE_CODES.ONBOARDING_FINISHED) return

    if (IS_QR_CODE_FLOW && !isMobile()) return

    poolingKYCStatus()
  }

  const handleDeposit = () => {
    onClose()
    openDepositDrawer()
    onFinish()
  }

  const handleLogout = () => {
    logoutUser()
    onClose()
  }

  return (
    <Modal
      theme="verifyAccount"
      isOpen={isOpen}
      onClose={onClose}
      title={translate('account.verification.verification')}
      hideClose
    >
      <StyledModalContentWrapper>
          <If 
            condition={step === 3}
            render={() => (
              <StyledFirstStepContainer>
                <StyledVerificationStatusTitle>
                  {translate('registration.selfieAndId.failTitle')}
                </StyledVerificationStatusTitle>

                <StyledCheckedIcon url={icon_selfie_and_id_failed} isFail />

                <StyledVerificationMessage>
                {rejectReason || translate('registration.selfieAndId.failDescription')}
                </StyledVerificationMessage>
        
                <Button $block onClick={() => setStep(1)}>
                  {translate('common.tryAgain')}
                </Button>

                <Button
                  $layout="link"
                  $colorSchema="black"
                  $weight={400}
                  style={{ textDecoration: 'underline', padding: 0 }}
                  onClick={handleLogout}
                >
                  {translate('navigation.logout')}
                </Button>
              </StyledFirstStepContainer>
            )} 
          />

        <If 
          condition={step === 0} 
          render={() => (
            <StyledFirstStepContainer>
              <StyledFirstStepContent>
                <StyledVerificationStatusTitle>
                  {translate('account.verfication.title')}
                </StyledVerificationStatusTitle>

                <Image url={selfieAndIdIcon} />

                <StyledVerificationMessage>
                  {translate('account.verfication.description')}
                </StyledVerificationMessage>
              </StyledFirstStepContent>
              <StyledFirstStepContent>
                <Button
                  $colorSchema="light"
                  $weight={400}
                  style={{ 
                    backgroundColor: themeStyles.colors.lightGreys[1].main 
                  }}
                  $roundeed
                  $size="sm"
                  onClick={toggleContactUs}
                  $leftIcon={<Image url={SupportChatIcon} />}
                >
                  {translate('account.verfication.contactSupport')}
                </Button>

                <Button $block onClick={() => setStep(1)}>
                  {translate('account.verfication.activateYourAccount')}
                </Button>
                <Button
                  $layout="link"
                  $colorSchema="black"
                  $weight={400}
                  style={{ textDecoration: 'underline', padding: 0 }}
                  onClick={handleLogout}
                >
                  {translate('navigation.logout')}
                </Button>
              </StyledFirstStepContent>
            </StyledFirstStepContainer>
          )}
        />

        <If
          condition={step === 1}
          render={() => (
            <CafIFrame
              minHeight={isMobile() ? '90vh' : '75vh'}
              chosenDocument={'IDENTIFICATION_AND_SELFIE'}
              closeIFrame={closeCafIFrame}
              requestParams={{ requestType: 'REGISTRATION_REQUEST' }}
            />
          )}
        />

        <If 
          condition={step === 2} 
          render={() => (
            <StyledFirstStepContainer>
              <StyledFirstStepContent>
                <Image url={Checkmark} />

                <StyledVerificationStatusTitle>
                  {translate('register.verificationSuccess')}
                </StyledVerificationStatusTitle>
                <StyledVerificationMessage>
                  {translate('register.verificationSuccessDescription')}
                </StyledVerificationMessage>
              </StyledFirstStepContent>

              <StyledFirstStepContent style={{ gap: 30 }}>
                <Button $block onClick={handleDeposit}>
                  {translate('navigation.deposit')}
                </Button>

                <Button
                  $layout="link"
                  $colorSchema="black"
                  $weight={400}
                  style={{ textDecoration: 'underline', padding: 0 }}
                  onClick={onClose}
                >
                  {translate('common.notNow')}
                </Button>

                <If 
                  condition={SHOW_OLD_BALANCE_WARNING}
                  render={() => (
                    <InfoBox 
                      title={translate('account.verification.balance.title')}
                      content={translate('account.verification.balance.content')}
                    />
                  )}
                />
              </StyledFirstStepContent>
            </StyledFirstStepContainer>
          )}
        />
      </StyledModalContentWrapper>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${EnvConfig.GATSBY_RECAPTCHA_KEY}`}
      />
    </Modal>
  )
}

KYCVerifyAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
}
