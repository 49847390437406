import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

import { useModalContext } from '../../../context/modalProvider'
import { getNetDeposits } from '../../../adapters/transactions'
import {
  getCoinDirectDepositUrl,
  getDirectPixProgress,
  getDirectaDepositUrl,
  getGigadatDepositUrl,
  getInovapayDirectPixData,
  getInovapayGatewayDepositUrl,
  getInovapayWalletDepositUrl,
  getPagSeguroData,
  getPagSeguroProgress,
  getPay4FunDepositUrl,
  getPay4FunGoDepositUrl,
  getPaylivreDepositUrl,
  requestDepositStatusV2,
  requestDepositV2,
} from '../../../adapters/payments'
import { recordGaData } from '../../../adapters/tracking'
import { getUpdatedToken } from '../../../utils/cookies'
import { getUserWallet } from '../../../adapters/user'
import { Field } from '../field'
import { FieldDropdown } from '../fieldDropdown'
import { Button } from '../../atoms/button'
import { toast } from '../../atoms/toast'
import { ValueInput } from '../../atoms/valueInput'
import { AmountSelector } from '../../atoms/AmountSelector'
import { DrawerSectionSubtitle } from '../../atoms/drawerSectionSubtitle'
import { DrawerSectionTitle } from '../../atoms/drawerSectionTitle'
import { OfferMessage } from '../../atoms/offerMessage'
import InovapayWallet from '../../organisms/deposit/inovapayWallet'
import { formatCurrency } from '../../../utils/currencyFormatting'
import { stringNormalize } from '../../../utils/stringNormalize'
import {
  PaymentProviders,
  Pay4FunGoMethodIds,
  PaymentStatus,
  ModalTypes,
} from '../../../utils/constants'
import { isCouponValid } from '../../../utils/couponValidation'
import { validateCoupon } from '../../../helpers/validators'
import { CPF_MASK, removeCpfMask } from '../../../helpers/mask'
import { validateCPF } from './utils'
import { EnvConfig } from '../../../config/EnvConfig'
import {
  BankAccountsWrapper,
  DepositContentContainer,
  StyledAccountsLink,
  StyledButtonWrapper,
  StyledIcon,
  StyledIconWrapper,
  WelcomeOfferTitle,
} from './styles'

import PromoCodeIcon from '../../../images/icon_coupon_black.svg'
import InformationIcon from '../../../images/icon_information.svg'
import LimitErrorIcon from '../../../images/icon_limit_reached_error.svg'

import { openExternalLink } from '../../../helpers/openExternalLink'
import { queryStringToObject } from '../../../helpers/stringHelper'
import { themeStyles } from '../../../styles/theme'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import { msToMinutesAndSeconds } from '../../../helpers/msToMinutesAndSeconds'
import { cookiePrefix } from '../../../utils/cookies'
import { useTranslation } from '../../../context/translationProvider'
import { generateURL } from '../../../utils/casinoProviders/generateURL'
import storageService from '../../../services/storageService'
import { pushDataLayer } from '../../../utils/pushDataLayer'
import { getWebsiteUrl } from '../../../utils/getWebsiteUrl'
import { useGroowthbookLocaleFeature } from '../../../hooks/useGrowthbook'
import { SelectBankAccountCard } from '../SelectBankAccountCard'
import { useEventTracking } from '../../../context/eventTrackingProvider'

const locale = process.env.GATSBY_INTL_LOCALE

export const DepositContent = ({
  user,
  depositLobbyData,
  cryptoCoins,
  depositClose,
  isMobile,
  initialData,
  isPage,
  usableBankAccounts,
}) => {
  const { open, close } = useModalContext()
  const {
    formState: { errors, isSubmitting },
    setValue,
    setError,
    control,
    watch,
    trigger,
    register,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  })
  const { translate } = useTranslation()
  const [showCouponInput, setShowCouponInput] = useState(false)
  const depositAmount = watch('depositAmount')
  const methodId = watch('methodId')
  const couponCode = watch('couponCode')
  const nationalId = watch('national_id')
  const { getSessionValue, setSessionValue, removeSessionValue } =
    useSessionStorage()

  const isBankAccountFlowEnabled = useGroowthbookLocaleFeature(
    'fe_igp_bank_accounts_regulation_flow'
  )

  const amountOptions = [...new Set(depositLobbyData?.defaultAmounts)]
  const isFirstDeposit = depositLobbyData?.firstDeposit
  const currencyCode = user?.wallet?.currency?.short_code
  const currencySymbol = user?.wallet?.currency?.symbol
  const method = depositLobbyData?.methods?.find(
    (method) => method?.paymentMethodDetailId === methodId
  )
  const isCrypto =
    method?.paymentMethodCode.toUpperCase() ===
    PaymentProviders.COIN_DIRECT_CRYPTO
  const maximumAmount = !isCrypto ? method?.maximumAmount : null
  const minimumAmount = !isCrypto ? method?.minimumAmount : null
  const code = method?.paymentMethodCode.toUpperCase()

  const isDepositDisabled = (() => {
    if (isSubmitting || (depositAmount <= 0 && !isCrypto)) return true
    if (errors?.depositAmount) return true
    if (usableBankAccounts?.length <= 0) return true

    return false
  })()

  const { trackEvent, EVENT_NAMES, EVENT_LOCATIONS } = useEventTracking()

  const handleSetDepositAmount = (value) => {
    setValue('depositAmount', value)
    trigger('depositAmount')
  }

  const handleSetSelectedMethod = (value) => {
    setValue('methodId', value)
  }

  const getPaymentMethodByCode = (code) => {
    return depositLobbyData?.methods?.find(
      (method) => method.paymentMethodCode === code
    )
  }

  const setPromoCodeMask = (e) => {
    e.target.value = stringNormalize(e?.target?.value || '')
  }

  const triggerSpentTimeGTMEvent = () => {
    const registrationStartTime = getSessionValue(
      `${cookiePrefix}:registration_start`
    )
    const depositFinishTime = new Date().getTime()

    if (registrationStartTime) {
      const fromRegisterTimeSpent = msToMinutesAndSeconds(
        depositFinishTime - registrationStartTime
      )

      pushDataLayer({
        eventName: 'registration_to_deposit_time_spent',
        data: {
          totalTime: fromRegisterTimeSpent,
        },
      })

      pushDataLayer({
        eventName: 'successful_deposit_after_registration',
        data: {
          totalTime: fromRegisterTimeSpent,
        },
      })
      removeSessionValue(`${cookiePrefix}:registration_start`)
    }

    const depositStartTime = getSessionValue(`${cookiePrefix}:deposit_start`)
    const depositTimeSpent = msToMinutesAndSeconds(
      depositFinishTime - depositStartTime
    )

    if (isFirstDeposit) {
      window?.dataLayer?.push({
        event: 'new_registration_deposit_time_spent',
        totalTime: depositTimeSpent,
      })
    } else {
      window?.dataLayer?.push({
        event: 'non_new_registration_deposit_time_spent',
        totalTime: depositTimeSpent,
      })
    }

    removeSessionValue(`${cookiePrefix}:deposit_start`)
  }

  const triggerGTMEvent = async ({ code }) => {
    const storedUser = storageService.getUser()
    const { short_code: currency = '' } = storedUser?.wallet?.currency || {}
    const {
      data: { total_num_of_deposits },
    } = await getNetDeposits({
      dateFrom: storedUser?.created_at,
      dateTo: new Date().toISOString().slice(0, 19),
      status: 'OK',
    })

    const alreadyDeposited = total_num_of_deposits > 1

    window?.dataLayer?.push({ ecommerce: null })

    if (!alreadyDeposited) {
      window?.dataLayer?.push({
        event: 'FirstDeposit',
        user_id: storedUser?.id,
        ecommerce: {
          transaction_id: `T_${storedUser?.id}_${new Date().getTime()}`,
          affiliation: `${EnvConfig.GATSBY_GTM_DOMAIN} Deposit`,
          value: depositAmount,
          tax: 0,
          shipping: 0,
          currency,
          coupon: '',
          items: [
            {
              item_id: `${code}`,
              item_name: `${code}`,
              affiliation: `${EnvConfig.GATSBY_GTM_DOMAIN} Deposit`,
              coupon: '',
              currency,
              discount: 0,
              index: 0,
              item_brand: `${code}`,
              item_category: `${code}`,
              item_category2: `${code}`,
              item_category3: `${code}`,
              item_category4: `${code}`,
              item_category5: `${code}`,
              item_list_id: 'deposit_method',
              item_list_name: 'Deposit Method',
              item_variant: '',
              location_id: '',
              price: depositAmount,
              quantity: 1,
            },
          ],
        },
      })
    } else {
      window?.dataLayer?.push({
        event: 'normalDeposit',
        user_id: storedUser?.id,
        ecommerce: {
          transaction_id: `T_${storedUser?.id}_${new Date().getTime()}`,
          affiliation: `${EnvConfig.GATSBY_GTM_DOMAIN} Deposit`,
          value: depositAmount,
          tax: 0,
          shipping: 0,
          currency,
          coupon: '',
          items: [
            {
              item_id: `${code}`,
              item_name: `${code}`,
              affiliation: `${EnvConfig.GATSBY_GTM_DOMAIN} Deposit`,
              coupon: '',
              currency,
              discount: 0,
              index: 0,
              item_brand: `${code}`,
              item_category: `${code}`,
              item_category2: `${code}`,
              item_category3: `${code}`,
              item_category4: `${code}`,
              item_category5: `${code}`,
              item_list_id: 'deposit_method',
              item_list_name: 'Deposit Method',
              item_variant: '',
              location_id: '',
              price: depositAmount,
              quantity: 1,
            },
          ],
        },
      })
    }
  }

  const trackDepositCompletedEvent = (responseData) => {
    try {
      const { transactionId } = responseData
      trackEvent(EVENT_NAMES.DEPOSIT_COMPLETED, {
        user_id: user?.id,
        transaction_id: transactionId,
        amount: depositAmount,
        account_balance: user?.wallet?.amount,
        deposit_method: method?.paymentMethodCode,
      })
    } catch (err) {}
  }

  const checkStatus = async (apiCall, reference) => {
    const response = await apiCall(reference)
    const statuses = {
      success: 'OK',
      declined: 'DECLINED',
      pending: 'PENDING',
      cancelled: 'CANCELLED',
    }

    const {
      transactionStatus,
      declinedStatusCode,
      status,
    } = response?.data || {}

    if (transactionStatus?.toUpperCase() === statuses.success) {
      triggerSpentTimeGTMEvent()
      await triggerGTMEvent({ code })
      toast.success(translate('payments.deposit.transactionCompleted'))
      window.postMessage('transactionCompleted', '*')
      trackDepositCompletedEvent(response?.data)
      close()

      return
    }

    if (
      transactionStatus?.toUpperCase() === statuses.declined
    ) {
      if(status?.toUpperCase() === statuses.cancelled) {
        switch (declinedStatusCode) {
          case '10051':
            toast.error(translate('payments.deposit.cancelled.10051'))
            break
          case '10067':
            toast.error(translate('payments.deposit.cancelled.10067'))
            break
          default:
            toast.error(translate('payments.deposit.cancelled.default'))
            break
        }
  
        close()
  
        return
      }

      triggerSpentTimeGTMEvent()
      await triggerGTMEvent({ code })
      toast.error(translate('payments.deposit.generalError'))
      close()

      return
    }

    setTimeout(() => checkStatus(apiCall, reference), 30000)
  }

  const handleSelectMinimumAmount = (minimum) => {
    const orderedAmounts =
      [...(depositLobbyData?.defaultAmounts || [])].sort((a, b) => a - b) || []
    const firstValidAmount =
      orderedAmounts?.find((amount) => amount >= minimum) || null

    handleSetDepositAmount(firstValidAmount)
  }

  const requestDynamicDepositStatus = async (reference) => {
    return requestDepositStatusV2({
      endpoint: method.statusApiUrl,
      transactionReference: reference,
    })
  }

  const onSubmit = async (data) => {
    try {
      if (data.couponCode) {
        const { messageCode } = await isCouponValid(data.couponCode)

        if (messageCode) {
          setError('couponCode', {
            message: translate('promoCode.error.expired'),
          })

          return
        }
      }

      let response = null
      let paymentRequested = false
      
      recordGaData({
        ga_cookie: getUpdatedToken('_ga'),
        ga_cookie_session: getUpdatedToken(EnvConfig.GATSBY_GA_COOKIE)
      })

      const siteurl = getWebsiteUrl()
      const baseURL = `${siteurl}/payment-success/`
      // Defining common parameters for the URLs
      const commonParams = [
        { param: 'type', value: 'DEPOSIT' },
        { param: 'provider', value: method?.paymentMethodCode },
        { param: 'amount', value: depositAmount },
        { param: 'lang', value: locale },
      ]
      const successParams = [
        ...commonParams,
        { param: 'status', value: 'success' },
        { param: 'currency', value: user.wallet.currency.short_code },
        { param: 'accountId', value: user.id },
      ]
      const errorParams = [...commonParams, { param: 'status', value: 'error' }]
      const backParams = [{ param: 'status', value: 'reset' }]
      // Generating urls
      const successUrl = generateURL({ baseURL, options: successParams })
      const errorUrl = generateURL({ baseURL, options: errorParams })
      const backUrl = generateURL({ baseURL, options: backParams })

      if (isBankAccountFlowEnabled) {
        paymentRequested = true
        response = await requestDepositV2({
          endpoint: method.transactionRequestUrl,
          body: {
            amount: depositAmount,
            nationalId:
              data.bankCpf ||
              data?.documentId ||
              removeCpfMask(data?.national_id),
            paymentMethodDetailId: method?.paymentMethodDetailId,
          },
        })
      } else {
        switch (code) {
          case PaymentProviders.GIGADAT_INTERAC_CPI:
            paymentRequested = true
            response = await getGigadatDepositUrl({
              amount: depositAmount,
              site: siteurl,
              payment_method: 'CPI',
              payment_method_detail_id: method?.paymentMethodDetailId,
            })

            break
          case PaymentProviders.PAY_4_FUN:
            paymentRequested = true
            response = await getPay4FunDepositUrl({
              ok_url: successUrl,
              not_ok_url: errorUrl,
              amount: depositAmount,
              payment_method_detail_id: method?.paymentMethodDetailId,
            })

            break
          case PaymentProviders.PAY_4_FUN_GO_PIX:
          case PaymentProviders.PAY_4_FUN_GO_BILLET:
          case PaymentProviders.PAY_4_FUN_GO_TI:
            paymentRequested = true
            response = await getPay4FunGoDepositUrl({
              ok_url: successUrl,
              not_ok_url: errorUrl,
              amount: depositAmount,
              payment_method: (() => {
                if (code === PaymentProviders.PAY_4_FUN_GO_PIX) {
                  return 'PIX'
                }

                if (code === PaymentProviders.PAY_4_FUN_GO_BILLET) {
                  return 'Boleto'
                }

                return 'BankTransfer'
              })(),
              payment_method_id: null,
              payment_method_detail_id: method?.paymentMethodDetailId,
            })

            break
          case PaymentProviders.PAY_4_FUN_GO_ITAU:
          case PaymentProviders.PAY_4_FUN_GO_BRADESCO:
          case PaymentProviders.PAY_4_FUN_GO_SANTANDER:
          case PaymentProviders.PAY_4_FUN_GO_BANCO_DO_BRASIL:
          case PaymentProviders.PAY_4_FUN_GO_CAIXA:
            paymentRequested = true
            response = await getPay4FunGoDepositUrl({
              ok_url: successUrl,
              not_ok_url: errorUrl,
              amount: depositAmount,
              payment_method: 'BankTransfer',
              payment_method_id: Pay4FunGoMethodIds[code],
              payment_method_detail_id: method?.paymentMethodDetailId,
            })

            break
          case PaymentProviders.PAYLIVRE_PIX:
          case PaymentProviders.PAYLIVRE_BANK_TRANSFER:
          case PaymentProviders.PAYLIVRE_BILLET:
            paymentRequested = true
            response = await getPaylivreDepositUrl({
              ok_url: siteurl,
              not_ok_url: errorUrl,
              amount: depositAmount,
              document_number: removeCpfMask(data?.customer_main_id),
              type: (() => {
                if (code === PaymentProviders.PAYLIVRE_PIX) {
                  return 'PIX'
                }

                if (code === PaymentProviders.PAYLIVRE_BILLET) {
                  return 'BILLET'
                }

                return 'WIRE_TRANSFER'
              })(),
              payment_methodId: null,
              payment_method_detail_id: method?.paymentMethodDetailId,
            })

            break
          case PaymentProviders.INOVAPAY_GATEWAY:
            paymentRequested = true
            response = await getInovapayGatewayDepositUrl({
              amount: depositAmount,
              nationalId:
                data.bankCpf ||
                data?.documentId ||
                removeCpfMask(data?.national_id),
              payment_method_detail_id: method?.paymentMethodDetailId,
              return_url: siteurl,
            })
            break
          case PaymentProviders.INOVAPAY_WALLET:
            paymentRequested = true
            response = await getInovapayWalletDepositUrl({
              amount: depositAmount,
              user_login: data.userLogin,
              user_secure_id: data.userSecureId,
              payment_method_detail_id: method?.paymentMethodDetailId,
            })
            break
          case PaymentProviders.COIN_DIRECT_CRYPTO:
            paymentRequested = true
            response = await getCoinDirectDepositUrl({
              pay_currency: data.payCurrency,
              payment_method_detail_id: method?.paymentMethodDetailId,
            })
            break
          case PaymentProviders.INOVAPAY_DIRECT_PIX:
            paymentRequested = true
            response = await getInovapayDirectPixData({
              amount: depositAmount,
              national_id:
                data.bankCpf ||
                data?.documentId ||
                removeCpfMask(data?.national_id),
              payment_method_detail_id: method?.paymentMethodDetailId,
            })
            break
          case PaymentProviders.PAGSEGURO:
            paymentRequested = true
            response = await getPagSeguroData({
              amount: depositAmount,
              nationalId:
                data.bankCpf ||
                data?.documentId ||
                removeCpfMask(data?.national_id),
              paymentMethodDetailId: method?.paymentMethodDetailId,
            })
            break
          default:
            break
        }
      }

      if (Object.values(PaymentProviders).includes(code) && !paymentRequested) {
        paymentRequested = true
        response = await getDirectaDepositUrl({
          value: depositAmount,
          back_url: backUrl,
          error_url: errorUrl,
          success_url: successUrl,
          payment_method: code.toUpperCase(),
          payment_method_detail_id: method?.paymentMethodDetailId,
        })
      }

      if (!paymentRequested) {
        toast.error('Payment not supported')
      }

      if (response) {
        if (response.ok) {
          const { payment_method, url: redirectionURL } = response.data

          if (isBankAccountFlowEnabled) {
            depositClose(false)

            getUserWallet()
            const paymentMethod = getPaymentMethodByCode(code)



            open({
              title: 'PIX',
              data: {
                ...response.data,
                id: response.data.reference,
                methodSelected: paymentMethod,
              },
              type: ModalTypes.QR,
            })

            checkStatus(requestDynamicDepositStatus, response.data.reference)
          } else if (code === PaymentProviders.COIN_DIRECT_CRYPTO) {
            openExternalLink(redirectionURL, '_blank')
            depositClose()
          } else if (code === PaymentProviders.INOVAPAY_DIRECT_PIX) {
            depositClose(false)

            getUserWallet()
            const paymentMethod = getPaymentMethodByCode(code)
            open({
              title: 'PIX',
              data: { ...response.data, methodSelected: paymentMethod },
              type: ModalTypes.QR,
            })

            checkStatus(getDirectPixProgress, response.data.reference)
          } else if (code === PaymentProviders.PAGSEGURO) {
            depositClose(false)

            getUserWallet()
            const paymentMethod = getPaymentMethodByCode(code)
            open({
              title: 'PIX',
              data: { ...response.data, methodSelected: paymentMethod },
              type: ModalTypes.QR,
            })

            checkStatus(getPagSeguroProgress, response.data.reference)
          } else if (!payment_method) {
            window.location = redirectionURL
          } else if (
            payment_method &&
            (payment_method?.payment_provider ===
              PaymentProviders.INOVAPAY_GATEWAY ||
              payment_method?.payment_provider ===
                PaymentProviders.INOVAPAY_WALLET)
          ) {
            depositClose()

            getUserWallet()
            triggerSpentTimeGTMEvent()

            open({
              title: translate('payments.receipt.deposit'),
              data: response.data,
              type: ModalTypes.RECEIPT,
            })
          }
        }

        if (!response.ok) {
          let message = translate('payments.deposit.generalError')
          const dataMessageCode = response.error.data?.errors?.[0]?.code
          const messageCode = dataMessageCode ?? response.error?.code
          let showErrorToast = true

          switch (messageCode) {
            case 147:
              {                
                message = translate('payments.depositLimitExceeded')
              }
              break
            case 52:
              {
                message = translate('payments.statusNotAllowed')
              }
              break
            case 460:
              {
                message = translate(
                  'account.paymentMethodsNotAvailable'
                )?.replace(
                  '{0}',
                  user?.wallet?.currency?.symbol ||
                    user.wallet.currency.short_code
                )
              }
              break
            case 212:
              {
                message = translate('payments.error.CPFrequired')
              }
              break
            case 506:
              {
                showErrorToast = false
                open({
                  type: ModalTypes.SIMPLE,
                  title: translate('common.deposit'),
                  data: {
                    img: LimitErrorIcon,
                    subtitle: translate(
                      'payments.error.exceededDepositLimitOfProvider.title'
                    ),
                    text: translate(
                      'payments.error.exceededDepositLimitOfProvider.text'
                    ),
                    buttonText: translate(
                      'payments.error.exceededDepositLimitOfProvider.button'
                    ),
                  },
                })
              }
              break
            case 502:
              {
                message = translate('payments.error.connectionIssue')
              }
              break
            case '210602':
              {
                message = translate('payments.error.exceededDepositLimit')
              }
              break
            case 24:
              {
                message = translate('payments.error.invalidBankAccountDetails')
              }
              break
            default:
              break
          }
          if (showErrorToast) toast.error(message)
        }

        paymentRequested = false
      }
    } catch (exc) {
      toast.error(translate('payments.deposit.generalError'))
    }
  }

  useEffect(() => {
    const onPaymentFailed = async (e) => {
      try {
        if (typeof e.data === 'string') {
          const data = JSON.parse(e.data)
          if (data && data.action === PaymentStatus.ERROR) {
            toast.error(translate('payments.receipt.error'))
          }

          if (data && data.action === PaymentStatus.CANCELLED) {
            switch (data.declinedStatusCode) {
              case 10051:
                toast.error(translate('payments.deposit.cancelled.10051'))
                break
              case 10067:
                toast.error(translate('payments.deposit.cancelled.10067'))
                break
              default:
                toast.error(translate('payments.deposit.cancelled.default'))
                break
            }
          }

          if (data && data.action === PaymentStatus.SUCCESS) {
            toast.success(translate('payments.deposit.success'))
            depositClose()
            await triggerGTMEvent({ code })
            triggerSpentTimeGTMEvent()

            open({
              title: translate('payments.receipt.deposit'),
              data: data,
              type: ModalTypes.RECEIPT,
            })
          }
        }
      } catch (ex) {
        console.error('error occured', ex)
      }
    }

    window.addEventListener('message', onPaymentFailed)

    return () => {
      window.removeEventListener('message', onPaymentFailed)
    }
  }, [open, depositClose])

  useEffect(() => {
    let firstMinimumAmount = 0
    const urlParams = queryStringToObject(window?.location?.search)

    if (urlParams?.action === 'deposit' && urlParams?.method) {
      const method = depositLobbyData?.methods?.find(
        (method) => method?.paymentMethodCode === urlParams?.method
      )
      if (method) handleSetSelectedMethod(method.paymentMethodDetailId)
    } else if (initialData?.method) {
      const { paymentMethodDetailId, minimumAmount: firstMinimum } =
        depositLobbyData?.methods?.find(
          (method) => method?.paymentMethodCode === initialData.method
        ) || {}

      if (!paymentMethodDetailId) {
        return
      }

      firstMinimumAmount = firstMinimum
      setValue('methodId', paymentMethodDetailId)
    } else {
      setValue('methodId', depositLobbyData?.methods[0]?.paymentMethodDetailId)
      firstMinimumAmount = depositLobbyData?.methods[0]?.minimumAmount
    }

    if (urlParams?.action === 'deposit' && urlParams?.amount) {
      handleSetDepositAmount(urlParams?.amount)
    } else if (initialData?.amount) {
      handleSetDepositAmount(initialData.amount)
    } else {
      handleSelectMinimumAmount(firstMinimumAmount)
    }

    if (initialData?.bonus) {
      if (!depositLobbyData?.couponCodeEnabled) {
        return
      }

      setShowCouponInput(true)
      setValue('couponCode', initialData.bonus.toUpperCase())
    }
  }, [initialData])

  useEffect(() => {
    trigger('depositAmount')
  }, [methodId])

  useEffect(() => {
    setSessionValue(`${cookiePrefix}:deposit_start`, new Date().getTime())
    if (!window) return

    window?.dataLayer?.push({
      event: 'Deposit',
    })

    window?.dataLayer?.push({
      event: 'deposits_page_viewed',
    })

    trackEvent(EVENT_NAMES.SCREEN_LOADED, {
      screen_name: EVENT_LOCATIONS.DEPOSIT,
    })

    return () => {
      removeSessionValue(`${cookiePrefix}:deposit_start`)
    }
  }, [])

  return (
    <DepositContentContainer
      isMobile={isMobile}
      onSubmit={handleSubmit(onSubmit)}
    >
      {isFirstDeposit ? (
        <WelcomeOfferTitle>
          {translate('payments.depositDrawer.firstDepositMessage')}
        </WelcomeOfferTitle>
      ) : null}

      {isCrypto ? (
        <section>
          <FieldDropdown
            label={translate('userProfile.currency')}
            errors={errors}
            autoCompleteOff
            removeFirstOption
            formProperties={register('payCurrency', { required: true })}
            values={cryptoCoins.map(({ code, name }) => (
              <option value={code} key={code}>
                {name}
              </option>
            ))}
          />

          <OfferMessage
            isInformation
            text={translate('depositCryptoDescription')}
            icon={InformationIcon}
          />
        </section>
      ) : (
        <section>
          <DrawerSectionTitle title={translate('depositAmountTitle1')} />
          <DrawerSectionSubtitle subtitle={translate('depositAmountTitle2')} />

          <Controller
            name="depositAmount"
            control={control}
            rules={{
              required: true,
              validate: (value) => {
                if (!value) {
                  return true
                }

                if (value < minimumAmount && value !== 0) {
                  return `${translate(
                    'payments.minimumDepositAmount'
                  )} ${currencySymbol}${minimumAmount}`
                }

                if (value > maximumAmount) {
                  return `${translate(
                    'payments.maximumDepositAmount'
                  )} ${currencySymbol}${maximumAmount}`
                }
              },
            }}
            render={({ field }) => (
              <ValueInput
                defaultValue={depositAmount}
                disabled={false}
                errors={errors?.depositAmount}
                forceValue={depositAmount}
                placeholder={`${minimumAmount}-${maximumAmount}`}
                currencySymbol={currencySymbol}
                {...field}
              />
            )}
          />

          <AmountSelector
            maximumAmount={maximumAmount}
            minimumAmount={minimumAmount}
            amountOptions={amountOptions}
            depositAmount={depositAmount}
            setDepositAmount={handleSetDepositAmount}
            currencyCode={currencyCode}
            currencySymbol={currencySymbol}
            fullWidth={!!isPage}
          />
        </section>
      )}

      <section>
        <DrawerSectionTitle
          title={translate('payments.depositDrawer.pixTitle')}
        />
        <DrawerSectionSubtitle
          subtitle={translate('payments.depositDrawer.pixSubtitle')}
        />

        <BankAccountsWrapper>
          {usableBankAccounts?.map((bankAccount) => (
            <SelectBankAccountCard
              key={bankAccount.id}
              isChecked={false}
              isDeposit
              onClick={() => {}}
              bankAccount={bankAccount}
            />
          ))}
        </BankAccountsWrapper>
      </section>

      {method?.paymentMethodCode.toUpperCase() ===
        PaymentProviders.INOVAPAY_WALLET && (
        <InovapayWallet register={register} errors={errors} />
      )}

      {[
        PaymentProviders.INOVAPAY_GATEWAY,
        PaymentProviders.INOVAPAY_DIRECT_PIX,
        PaymentProviders.PAGSEGURO,
      ].includes(method?.paymentMethodCode.toUpperCase()) && (
        <>
          {user.national_id ? (
            (() => {
              if (nationalId) {
                return
              }

              setValue('national_id', user?.national_id)
            })()
          ) : (
            <Field
              noBottomMargin
              label="Documento de Identificação (CPF)"
              maxLength="14"
              placeholder="XXX.XXX.XXX-XX"
              mask={CPF_MASK}
              defaultValue={user?.national_id || ''}
              errors={errors}
              showErrorMsg
              formProperties={register('national_id', {
                required: true,
                validate: (value) =>
                  validateCPF(
                    value,
                    translate('cpf.register.invalidFormat'),
                    user
                  ),
              })}
            />
          )}
        </>
      )}

      {depositLobbyData?.couponCodeEnabled && showCouponInput ? (
        <section>
          <Field
            noBottomMargin
            errors={errors}
            showErrorMsg
            label={translate('depositCouponHint')}
            labelButton={
              <button onClick={() => setShowCouponInput(false)}>
                {translate('depositCouponCancel')}
              </button>
            }
            onChange={setPromoCodeMask}
            maxLength="8"
            fieldValue={couponCode}
            inputSuffix={
              <StyledIcon src={PromoCodeIcon} alt="Promo code icon" />
            }
            formProperties={register('couponCode', {
              validate: (value) =>
                validateCoupon(value, translate('promoCode.error.notExist')),
            })}
          />
        </section>
      ) : null}

      <StyledAccountsLink to="/account/bank-accounts">
        {translate('payments.bankAccountKeys.ManageCTA')}
      </StyledAccountsLink>

      <section>
        <Button
          expand
          disabled={isDepositDisabled}
          id="btnDepositModal"
          loading={isSubmitting}
        >
          {method?.paymentMethodCode?.toUpperCase() ===
          PaymentProviders?.COIN_DIRECT_CRYPTO ? (
            'Continuar'
          ) : (
            <>
              {translate('payments.depositDrawer.depositButton').replace(
                '{amount}',
                formatCurrency(
                  depositAmount || 0,
                  {
                    shortCode: user?.wallet?.currency?.short_code,
                    currencySymbol: user?.wallet?.currency?.symbol,
                    maximumFractionDigits: 2,
                    useGrouping: depositAmount?.toString().length >= 5,
                    locale,
                  },
                  false
                )
              )}
            </>
          )}
        </Button>

        {depositLobbyData?.couponCodeEnabled && !showCouponInput ? (
          <StyledButtonWrapper>
            <Button
              expand
              onClick={() => setShowCouponInput(true)}
              backgroundColor="transparent"
              textColor={themeStyles.colors.black.main}
              uppercase={false}
              bold={false}
            >
              <StyledIconWrapper>
                <StyledIcon src={PromoCodeIcon} alt="Promo code icon" />
              </StyledIconWrapper>
              {translate('depositCouponCode')}
            </Button>
          </StyledButtonWrapper>
        ) : null}
      </section>

      {method?.paymentMethodCode.toUpperCase() ===
        PaymentProviders.GIGADAT_INTERAC_CPI && (
        <small>® Trade-mark of Interac Corp. Used under license.</small>
      )}
    </DepositContentContainer>
  )
}

DepositContent.propTypes = {
  user: PropTypes.shape({
    created_at: PropTypes.string,
    id: PropTypes.string,
    national_id: PropTypes.string,
    wallet: PropTypes.object,
  }),
  cryptoCoins: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  depositClose: PropTypes.func,
  depositLobbyCMSData: PropTypes.shape({
    headerText: PropTypes.string,
    offerSection: PropTypes.shape({
      description: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  depositLobbyData: PropTypes.shape({
    defaultAmounts: PropTypes.arrayOf(PropTypes.number),
    firstDeposit: PropTypes.bool,
    couponCodeEnabled: PropTypes.bool,
    methods: PropTypes.arrayOf(
      PropTypes.shape({
        maximumAmount: PropTypes.number,
        minimumAmount: PropTypes.number,
        paymentMethodCode: PropTypes.string,
        paymentMethodDetailId: PropTypes.string,
      })
    ),
  }),
  initialData: PropTypes.shape({
    amount: PropTypes.number,
    bonus: PropTypes.string,
    method: PropTypes.string,
  }),
  isPage: PropTypes.bool,
  isMobile: PropTypes.bool,
}
