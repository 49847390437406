import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

import { fetcher } from '../utils/adapterFunctions'

export const useQuery = (path, { condition, ...config } = {}) => {
  const shouldFetch = typeof condition === 'boolean'
    ? condition
    : true

  return useSWR(shouldFetch ? path : undefined, fetcher, config)
}

export const useImmutableQuery = (path, { condition, ...config } = {}) => {
  const shouldFetch = typeof condition === 'boolean'
    ? condition
    : true

  return useSWRImmutable(shouldFetch ? path : undefined, fetcher, config)
}
