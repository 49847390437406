import React, { useContext } from 'react'
import { useFooterV2ApiData } from '../../../hooks/graphqlStaticQuery/useFooterV2ApiData'
import {
  FooterImageSectionContainer,
  FooterImageSectionImages,
  FooterImageSectionTitle,
} from './styles'
import { Image } from '../../atoms/image'
import { If } from '../../atoms/if'
import { filterVisibleFooterItems } from '../../../dataManipulation/cmsItems'
import { AuthContext } from '../../../context/authProvider'
import { getLinkRel } from '../../../utils/getLinkRel'

const FooterImagesSections = () => {
  const { image_sections } = useFooterV2ApiData()
  const { isLoggedIn } = useContext(AuthContext)
  const filteredSections = image_sections
    ?.map((section) => {
      return {
        images: filterVisibleFooterItems(section?.images, isLoggedIn),
        title: section?.title,
      }
    })
    ?.filter((section) => section?.images?.length > 0)

  const renderImage = (imageData, j) => (
    <Image
      key={`footer-image-${j}`}
      url={imageData.image?.url}
      alt={imageData.image?.url}
      width={imageData.image?.width}
      height={imageData.image?.height}
    />
  )

  return (
    <FooterImageSectionContainer>
      {filteredSections?.map((section, i) => (
        <div key={`footer-image-section-${i}`}>
          <FooterImageSectionTitle>{section?.title}</FooterImageSectionTitle>
          <FooterImageSectionImages>
            {section?.images?.map((imageData, j) => (
              <If
                key={`footer-image-wrapper-${j}`}
                condition={imageData?.link?.url}
                render={() => (
                  <a
                    href={imageData.link.url}
                    target={imageData.link.target}
                    rel={getLinkRel(imageData.link.target)}
                    key={`footer-image-link-${j}`}
                  >
                    {renderImage(imageData, j)}
                  </a>
                )}
                renderElse={() => renderImage(imageData, j)}
              />
            ))}
          </FooterImageSectionImages>
        </div>
      ))}
    </FooterImageSectionContainer>
  )
}

export { FooterImagesSections }
