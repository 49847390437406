import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const StyledMain = styled.main`  
  ${({ $childrenFullHeight }) => $childrenFullHeight && css`
    min-height: 100vh;
  `}

  ${({ $childrenBackground }) => {
    if ($childrenBackground === 'black') {
      return css`
        background-color: ${themeStyles.colors.black.main};
      `
    }

    return css`
      background-color: ${themeStyles.colors.white.main};
    `
  }}
`
