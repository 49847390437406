import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const Content = styled.div`
  padding: 1.5em;
`

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0 3em;
`

export const EmptyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4em;
  padding: 1em 0 3em;
`

export const StyledCashbackButtonWrapper = styled.div`
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  padding: 1em;
  width: 100%;
  z-index: 10;
`

export const StyledOfferMessageContainer = styled.div`
  > section {
    align-items: flex-start;

    a {
      color: ${themeStyles.colors.accentColors.secondary.main};
    }
  }
`
