import storageService from '../services/storageService'

const generateId = () => {
  const randomAlphanumeric = Math.random().toString(36).substring(2, 15)
  const timestamp = Date.now().toString(36)
  return randomAlphanumeric + timestamp
}

export const getOrCreateBrowserId = () => {
  let growthbookBrowserId = storageService.getValue('growthbookBrowserId')
  if (!growthbookBrowserId) {
    growthbookBrowserId = generateId()
    storageService.setValue('growthbookBrowserId', growthbookBrowserId)
  }
  return growthbookBrowserId
}
