import axios from 'axios'
import mixpanel from 'mixpanel-browser'

import storageService from '../../services/storageService'
import { ErrorCodes } from '../../config/ErrorCodes'
import { EnvConfig } from '../../config/EnvConfig'
import { refreshToken } from './refreshToken'
import { getMobileAppVersion } from '../../utils/mobileAppFunctionality'
import packageJson from '../../../package.json'

const HTTP_TIMEOUT = 30000

let requestsQueue = []
let isRefreshing = false

const http = axios.create({
  baseURL: EnvConfig.API_URL,
  timeout: HTTP_TIMEOUT,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
})

http.interceptors.request.use(async (req) => {
  const token = storageService.getAccessToken()

  const isRefreshTokenEndpoint = req.url.includes('/auth/refresh-token')

  const mobileVersion = getMobileAppVersion()
  if (mobileVersion) {
    req.headers['X-Requested-With'] = `KTOMobileApp_${mobileVersion}`
  } else {
    req.headers['X-Requested-With'] = `KTOWeb_${packageJson.version}`
  }

  if (token && !isRefreshTokenEndpoint) {
    req.headers['Authorization'] = `Bearer ${token}`
  }

  return Promise.resolve(req)
})

http.interceptors.response.use(
  (response) => response,
  async (err) => {
    const isActivateAccountEndpoint =
      err?.config?.url?.includes('/auth/activate')
    const isRefreshTokenEndpoint = err?.config?.url?.includes(
      '/auth/refresh-token'
    )

    if (err.response && err.response.status === 401) {
      if (isRefreshTokenEndpoint) {
        storageService.clearTokens()
        window.location = '/'
        try {
          mixpanel.reset()
        } catch (err) {
          console.log('EventTracking: error resetting user identity')
        }
        return Promise.reject(err)
      }

      if (
        // err.request.responseURL.indexOf('user') < 0 &&
        err.request.responseURL.indexOf('registration') < 0 &&
        err.request.responseURL.indexOf('login') < 0 &&
        err.request.responseURL.indexOf('password') < 0
      ) {
        const errorCode = err.response.data.messageCode

        if (
          errorCode !== ErrorCodes.USER_LOCKED_TO_LOGIN &&
          errorCode !== ErrorCodes.WRONG_CREDENTIALS &&
          !isActivateAccountEndpoint
        ) {
          if (!isRefreshing) {
            isRefreshing = true
            await refreshToken(err, requestsQueue, http)
            isRefreshing = false
            requestsQueue = []
          }
        }

        return new Promise((resolve, reject) => {
          requestsQueue.push({ config: err.config, resolve, reject })
        })
      }
    }

    return Promise.reject(err)
  }
)

export default http
