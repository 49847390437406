import React, { useContext, useEffect } from 'react'
import { Button } from './../../../atomic-components/atoms/button'
import { AuthContext } from '../../../context/authProvider'
import { Text } from '../../atoms/text'
import { useTranslation } from '../../../context/translationProvider'
import { StyledTopBanner } from './style'

export const TopBannerLocation = () => {
  const { setTriggerGeolocation } = useContext(AuthContext)
  const { translate } = useTranslation()

  const toggleContactUs = () => {
    if (window.isIntercomOpen) window.Intercom('hide')
    else {
      window.Intercom('show')
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer?.push({
        event: 'top_banner_prompt'
      })
    }
  }, [])

  return (
    <StyledTopBanner>
      <Text>
        {translate('geolocation.locationBlocked')}
      </Text>
      <Button bordered onClick={() => { 
        if (typeof window !== 'undefined') {
          window.dataLayer?.push({
            event: 'top_banner_cx'
          })
        }
        toggleContactUs()
      }}>
        {translate('geolocation.startChat')}
      </Button>
      <Button onClick={() => { 
        if (typeof window !== 'undefined') {
          window.dataLayer?.push({
            event: 'top_banner_steps'
          })
        }
        setTriggerGeolocation(true)}
      }>
        {translate('geolocation.turnOnLocation')}
      </Button>
    </StyledTopBanner>
  )
}
