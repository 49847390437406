import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '../../atoms/text'
import {
  StyledButtonsWrapper,
  StyledContainer,
  StyledContent,
  StyledTagWrapper,
  StyledWrapper,
} from './styles'
import { DeleteButton } from '../../atoms/DeleteButton'
import { useTranslation } from '../../../context/translationProvider'
import { If } from '../../atoms/if'
import { Tag } from '../../atoms/tag'
import { DefaultBankAccountButton } from '../../atoms/defaultBankAccountButton'
import { Image } from '../../atoms/image'
import { getBankIcon } from '../../../utils/bankIcons'

export const MyAccountBankAccountCard = ({
  bankAccount = {},
  onDelete = () => {},
  onUpdateDefault = () => {},
  showOnly,
}) => {
  const { translate } = useTranslation()

  const bankAccountTypes = {
    CRN: translate('payments.currentAccount'),
    PAC: translate('payment.paymentAccount'),
    SVN: translate('payment.savingsAccount'),
    SAL: translate('payment.salaryAccount'),
  }

  const isPendingDeposit = bankAccount.status === 'PENDING_VALIDATION'
  const isDefault = bankAccount.defaultForWithdrawal
  const isSalaryAccount = bankAccount.accountType === 'SAL'

  const handleDelete = (event) => {
    event.stopPropagation()
    onDelete({ bankAccount, isActive: true })
  }

  const handleUpdateDefault = (event) => {
    event.stopPropagation()
    onUpdateDefault(bankAccount.id)
  }

  const bankIcon = getBankIcon(bankAccount?.bank?.code, true)

  return (
    <StyledContainer showOnly={showOnly}>
      <StyledWrapper>
        <Image url={bankIcon} alt={bankAccount?.bank.name} width={40} />
        <StyledContent>
          <div>
            <Text bold style={{ marginBottom: 5, lineHeight: '26px' }}>
              {bankAccount.bank.name}
            </Text>
            <Text small style={{ marginBottom: 4, fontSize: 14 }}>
              Ag {bankAccount.bankBranch} | {bankAccount.accountNumber}
            </Text>
            <Text small style={{ marginBottom: 5, fontSize: 14 }}>
              {bankAccountTypes[bankAccount.accountType]}
            </Text>

            <StyledTagWrapper>
              <If
                condition={isDefault && !showOnly}
                render={() => (
                  <Tag
                    theme="secondary"
                    text={translate('payments.bankAccountKeys.tags.default')}
                  />
                )}
              />

              <If
                condition={isSalaryAccount && !showOnly}
                render={() => (
                  <Tag
                    theme="grey"
                    text={translate('payments.bankAccountKeys.tags.salaryAccount')}
                  />
                )}
              />

              <If
                condition={isPendingDeposit && !showOnly}
                render={() => (
                  <Tag
                    theme="grey"
                    text={translate(
                      'payments.bankAccountKeys.list.depositRequired'
                    )}
                    fontWeight="bold"
                  />
                )}
              />
            </StyledTagWrapper>

            <StyledButtonsWrapper>
              <If
                condition={
                  !isPendingDeposit && 
                  !isDefault && 
                  !showOnly && 
                  !isSalaryAccount
                }
                render={() => (
                  <DefaultBankAccountButton onClick={handleUpdateDefault} />
                )}
              />

              <If
                condition={!showOnly}
                render={() => <DeleteButton onDelete={handleDelete} />}
              />
            </StyledButtonsWrapper>
          </div>
        </StyledContent>
      </StyledWrapper>
    </StyledContainer>
  )
}

MyAccountBankAccountCard.propTypes = {
  isDefault: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdateDefault: PropTypes.func,
  bankAccount: PropTypes.shape({
    bankBranch: PropTypes.string,
    accountNumber: PropTypes.string,
    accountType: PropTypes.string,
    bank: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
}
