import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import {
  SimplePopUpClose,
  SimplePopUpContent,
  SimplePopUpHeader,
  SimplePopUpOverlay,
  SimplePopUpWrapper,
} from './style'
import PropTypes from 'prop-types'
import TimesIconBlack from '../../../images/icon_times_black.svg'
import useResizeListener from '../../../hooks/useResizeListener'

const SimplePopUp = (props) => {
  const { onClose, popUpMinimunWidth } = props

  const [mobileBodyHeight, setMobileBodyHeight] = useState(window?.innerHeight)

  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => document.body.classList.remove('overflow-hidden')
  }, [])

  useResizeListener(() => {
    setMobileBodyHeight(window?.innerHeight)
  })

  return createPortal(
    <SimplePopUpWrapper {...props}>
      <SimplePopUpOverlay />
      <SimplePopUpContent
        popUpMinimunWidth={popUpMinimunWidth}
        mobileHeight={mobileBodyHeight}
      >
        <SimplePopUpHeader>
          <SimplePopUpClose
            src={TimesIconBlack}
            alt="modal-basic-close"
            onClick={onClose}
          />
        </SimplePopUpHeader>
        {props.children}
      </SimplePopUpContent>
    </SimplePopUpWrapper>,
    document.body
  )
}

SimplePopUp.defaultProps = {
  popUpMinimunWidth: 600,
}

SimplePopUp.propTypes = {
  onClose: PropTypes.func.isRequired,
  popUpMinimunWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default SimplePopUp
