import React from 'react'
import PropTypes from 'prop-types'

import { StyledIcon, StyledOfferMessageContainer } from './styles'
import { If } from '../if'

import GiftIcon from '../../../images/icon_gift_black.svg'
const DEFAULT_ICON = GiftIcon

const OfferMessage = ({ text, icon, isInformation, theme, hasHtml }) => {
  return (
    <StyledOfferMessageContainer isInformation={isInformation} theme={theme}>
      <StyledIcon
        isInformation={isInformation}
        backgroundUrl={icon ? icon : DEFAULT_ICON}
      />
      <If
        condition={hasHtml}
        render={() => <span dangerouslySetInnerHTML={{ __html: text }} />}
        renderElse={() => <span>{text}</span>}
      />
    </StyledOfferMessageContainer>
  )
}

OfferMessage.defaultProps = {
  text: '',
  icon: null,
}

OfferMessage.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  theme: PropTypes.string,
}

export { OfferMessage }
