import { styled, css } from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const StyledBottomBar = styled.div`
  ${themeMedia.maxMobile`
    position: fixed;
    width: 100%;
    z-index: 3015; //higher than kambi betslip, lower than side menu
    height: 64px;
    bottom: -1px;
    display: flex;
    align-items: center;
    background-color: #000;
    a {
      text-decoration: none;
    }
    > div {
      height: 100%; 
    }
  `}
  display: none;
`

export const StyledBottomBarAnchor = styled.div`
  ${themeMedia.maxMobile`
    display: block;
    width: 100%;
    height: 0px;
    position: fixed;
    bottom: 63px;
    z-index: 1000;
  `}
  display: none;
`

export const StyledBottomNavButton = styled.a`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 56px;
  background-color: #000;
  border-bottom: 3px solid transparent;
  ${({ $selected }) => {
    return (
      $selected &&
      css`
        border-bottom: 3px solid #da0000;
      `
    )
  }}
`

export const StyledBottomNavButtonIcon = styled.img`
  height: 30px;
  filter: opacity(0.7);
  ${({ $selected }) =>
    $selected &&
    css`
      filter: opacity(1);
    `}
`

export const StyledBottomNavButtonLabel = styled.p`
  margin: 0;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  text-wrap: wrap;
  text-align: center;
  filter: opacity(0.7);

  ${({ $selected }) =>
    $selected &&
    css`
      filter: opacity(1);
    `}
`
export const StyledBottomNavChangeContextButton = styled.img`
  height: 48px;
`
