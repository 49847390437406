export const detectDeviceAndBrowser = () => {
  const { userAgent } = navigator

  let deviceType = 'Desktop'
  let browser = 'Unknown'

  if (/android/i.test(userAgent)) {
    deviceType = 'Android'
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    deviceType = 'iOS'
  }

  if (/chrome|chromium|crios/i.test(userAgent) && !/edg/i.test(userAgent)) {
    browser = 'Google Chrome'
  } else if (/firefox|fxios/i.test(userAgent)) {
    browser = 'Mozilla Firefox'
  } else if (
    /safari/i.test(userAgent) &&
    !/chrome|crios|chromium/i.test(userAgent)
  ) {
    browser = 'Safari'
  } else if (/edg/i.test(userAgent)) {
    browser = 'Microsoft Edge'
  }

  return {
    browser,
    deviceType
  }
}