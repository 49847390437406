import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'
import { Link } from 'gatsby'

export const DepositContentContainer = styled.form`
  display: flex;

  flex-direction: column;
  gap: 1.25rem;
  padding: 0 26px 1rem 26px;
  padding-top: ${({ isMobile }) => (isMobile ? '22px' : '57px')};
  color: ${themeStyles.colors.black.main};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: left;
  }
`

export const WelcomeOfferTitle = styled.h1`
  font-weight: ${brandConfig.fonts.text.h1.weight};
  font-size: ${brandConfig.fonts.text.h1.size.mobile};
  font-family: ${brandConfig.fonts.text.h1.family};
  font-style: ${brandConfig.fonts.text.h1.style};
  text-transform: uppercase;
  word-wrap: break-word;
  margin: 0;
`

export const StyledIconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: ${themeStyles.colors.accentColors.secondary.main};
  margin-right: 5px;
`

export const StyledIcon = styled.img`
  display: flex;
  max-width: 24px;
  width: 100%;
  max-height: 24px;
  height: 100%;
`

export const StyledQrCodeButton = styled.button`
  display: flex;
  flex-shrink: 0;
  gap: 8px;
  margin: 12px auto 0;
  align-items: center;
  justify-content: center;

  max-height: 24px;
  height: 100%;

  background-color: transparent;

  font-family: ${brandConfig.fonts.text.paragraph.family};
  font-size: ${brandConfig.fonts.text.paragraph.size.desktop};
  font-style: normal;
  font-weight: ${brandConfig.fonts.text.paragraph.weight};
  color: ${themeStyles.colors.black.main};
  line-height: 115%;
`

export const StyledButtonWrapper = styled.div`
  margin-top: 17px;

  button {
    letter-spacing: inherit;
  }
`

export const StyledAccountsLink = styled(Link)`
  color: var(--Primary-Colours-Black, #000);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  text-decoration-style: solid;
  margin-bottom: 40px;

  cursor: pointer;
`

export const BankAccountsWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`
