import React from 'react'

export const DeleteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.64 7.09H18.65V8.36H18.13L17.18 20.5H7.56L6.49 8.36H6V7.09H8.98L9.83 4H14.79L15.64 7.09ZM13.81 5.27H10.8H10.79L10.29 7.09H14.31L13.81 5.27ZM8.72 19.23H16L16.85 8.36H7.76L8.72 19.23ZM13.752 17.3711L14.3472 9.87469L15.6132 9.97522L15.018 17.4716L13.752 17.3711ZM8.99805 9.98096L10.2641 9.88043L10.8593 17.3768L9.59329 17.4774L8.99805 9.98096ZM12.9399 9.93018H11.6699V17.4302H12.9399V9.93018Z"
        fill="black"
      />
    </svg>
  )
}
