import { useEffect, useContext, useState, useRef, useCallback } from 'react'
import { AuthContext } from '../context/authProvider'
import storageService from '../services/storageService'
import { ONE_SECOND, THREE_SECONDS } from '../utils/constants'
import { useLocation } from '@reach/router'
import useSlugs from './useSlugs'

const INTERCOM_API_BASE = process.env.GATSBY_INTERCOM_API_BASE
const INTERCOM_API_APP_ID = process.env.GATSBY_INTERCOM_API_APP_ID

const useIntercomMessenger = () => {
  const { pathname: currentPath } = useLocation()
  const [sportsSlug, liveSportsSlug] = useSlugs(['sports', 'live-sports'])
  const startManuallyPaths = [`/${sportsSlug}`, `/${liveSportsSlug}`]

  const { isLoggedIn, user } = useContext(AuthContext)
  const [messengerAudience, setMessengerAudience] = useState(null)
  const [isIntercomReady, setIsIntercomReady] = useState(false)

  const [timeoutId, setTimeoutId] = useState()
  const timeoutIdRef = useRef(timeoutId)
  timeoutIdRef.current = timeoutId

  const setIntercomOnBoot = (openOnBoot, path) => {
    if (!openOnBoot && !path) return

    if (openOnBoot?.type == 'search_browse_article') {
      return storageService.setIntercomOnBoot(
        JSON.stringify(['showArticle', openOnBoot?.metadata?.articleIds?.[0]])
      )
    }
    if (openOnBoot?.type == 'conversation') {
      return storageService.setIntercomOnBoot(
        JSON.stringify([
          'showConversation',
          openOnBoot?.metadata?.conversationId,
        ])
      )
    }
    const cleanPath = path?.replaceAll('/', '')
    if (['home', 'messages', 'help'].includes(cleanPath)) {
      return storageService.setIntercomOnBoot(
        JSON.stringify(['showSpace', cleanPath])
      )
    }
  }

  const checkIntercomState = useCallback(() => {
    if (!window.isIntercomOpen) return
    const newTimeoutId = setTimeout(checkIntercomState, THREE_SECONDS)
    setTimeoutId(newTimeoutId)
    const intercomState = JSON.parse(
      localStorage.getItem('intercom.intercom-state')
    )
    const openOnBoot = intercomState?.openOnBoot
    const path = intercomState?.router?.location?.pathname

    setIntercomOnBoot(openOnBoot, path)
  }, [setTimeoutId, setIntercomOnBoot])

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
    }
  }, [])

  const callShowFunctionIfNeeded = () => {
    if (!JSON.parse(storageService.getIsIntercomOpen())) return
    const intercomShowOptions = JSON.parse(storageService.getIntercomOnBoot())
    window.Intercom('show')
    if (intercomShowOptions) window.Intercom(...intercomShowOptions)
  }

  const onIntercomLoaded = () => {
    window.removeEventListener('INTERCOM_LOADED', onIntercomLoaded)
    setIsIntercomReady(true)
    setTimeout(callShowFunctionIfNeeded, ONE_SECOND)
  }

  const onShow = useCallback(() => {
    window.isIntercomOpen = true
    storageService.setIsIntercomOpen(true)
    checkIntercomState()
  }, [checkIntercomState])

  const onHide = useCallback(() => {
    window.isIntercomOpen = false
    storageService.setIsIntercomOpen(false)
    clearTimeout(timeoutIdRef.current)
  }, [])

  const showMessenger = useCallback((userData) => {
    // Intercom also has Intercom('update')
    // but it doesnt remove the previous chat, so this is safer
    window.Intercom('shutdown')
    window.Intercom('boot', {
      api_base: INTERCOM_API_BASE,
      app_id: INTERCOM_API_APP_ID,
      user_id: userData?.user_id || null,
      name: userData?.name || null,
      email: userData?.email || null,
      user_hash: userData?.hash || null,
    })

    window.Intercom('onShow', onShow)
    window.Intercom('onHide', onHide)
  }, [onShow, onHide])

  const startIntercom = useCallback(() => {
    if (!isIntercomReady) return

    if (!isLoggedIn || !user) {
      if (messengerAudience === 'logged-out') return
      setMessengerAudience('logged-out')
      showMessenger()
    } else {
      if (messengerAudience === 'logged-in') return
      setMessengerAudience('logged-in')

      showMessenger({
        user_id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        hash: user.intercomToken,
      })
    }
  }, [isIntercomReady, isLoggedIn, user, messengerAudience, setMessengerAudience, showMessenger])

  useEffect(() => {
    window.isIntercomOpen = false
    window.addEventListener('INTERCOM_LOADED', onIntercomLoaded)
    if (window.intercomScriptLoaded) onIntercomLoaded()
    if (!currentPath) return

    const needStartManually = startManuallyPaths.some(
      path => currentPath.startsWith(path)
    )
    if (!needStartManually) startIntercom()

    return () => window.removeEventListener('INTERCOM_LOADED', onIntercomLoaded)
  }, [startManuallyPaths, currentPath, startIntercom])

  return { startIntercom }
}

export default useIntercomMessenger
