import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

const grey = '#dbdbdb'

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${grey};
  gap: 10px;
  padding: 19px 0px;

  ${({ showOnly }) => showOnly && `border-top: 1px solid ${grey};`}

  ${themeMedia.desktop`
    flex-direction: row;
    align-items: center;
    padding: 15px 30px;
    gap: 40px;
  `}
`

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  ${themeMedia.desktop`
    flex-direction: row;
    align-items: center;
    gap: 40px;
  `}
`

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const StyledChevronRight = styled.img`
  display: block;
  width: 30px;
  margin: 0 0 0 auto !important;
`

export const StyledTagWrapper = styled.div`
  margin-bottom: 15px;
`

export const StyledButtonsWrapper = styled.section`
  display: flex;
  gap: 1.25rem;
  margin-left: auto;
`
