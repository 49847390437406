export const getUserProfileProperties = (user) => {
    const {
        wallet,
        user_status,
        accept_news,
        accept_sms,
        accept_gdpr,
        accept_gdpr_marketing,
        accept_pp,
        accept_tc,
        is_push_notifications_allowed,
        created_at,
        last_login,
        national_id_status,
        kyc_status,
        email_verification_status,
        mobile_verification_status,
        auto_withdrawal_enabled,
        year_of_birth
    } = user

    return {
        accept_news,
        accept_sms,
        accept_gdpr,
        accept_gdpr_marketing,
        accept_pp,
        accept_tc,
        is_push_notifications_allowed,
        created_at,
        last_login,
        national_id_status,
        kyc_status,
        email_verification_status,
        mobile_verification_status,
        auto_withdrawal_enabled,
        year_of_birth,
        user_status,
        wallet: {
            amount: wallet?.amount,
            active: wallet?.active,
            blocked_amount: wallet?.blocked_amount,
            unplayed_balance: wallet?.unplayed_balance
        }
    }
}