import styled, { css } from 'styled-components'

export const StyledPromoTag = styled.span`
  font-size: 0.7em;
  font-weight: 600;
  line-height: 17px;
  border-radius: 3px;
  padding: 0.3em 0.6em;
  text-align: center;
  flex-shrink: 0;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};

  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `}

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      font-size: 0.8em;
      /* line-height: 17px; */
      text-transform: uppercase;
      padding: 0.2rem 0.5rem;
    `}
`
