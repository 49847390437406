export const useSessionStorage = () => {
  const setSessionValue = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value))
  }

  const getSessionValue = (key) => {
    const storedValue = sessionStorage.getItem(key)

    if (!storedValue) {
      return null
    }

    return storedValue !== null ? JSON.parse(storedValue) : null
  }

  const removeSessionValue = (key) => {
    sessionStorage.removeItem(key)
  }

  return {
    setSessionValue,
    getSessionValue,
    removeSessionValue,
  }
}
