import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const ResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  gap: 1.3rem;
  ${themeMedia.mobile`
    padding: 1rem 3.5rem 1.5rem 3.5rem;
  `}

  ${themeMedia.desktop`
    padding: 1rem 4rem 2rem 4rem;
  `};
`

export const VerifiedIcon = styled.img``

export const StyledHeaderIcon = styled.div`
  margin-bottom: 0.3rem;
`

export const StyledMainTitle = styled.div`
  font-size: 25px;
  font-weight: 700;
  text-align: center;
`

export const StyledMainDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`

export const StyledButton = styled.div`
  width: 100%;
`
