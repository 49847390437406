import React, { createContext, useContext, useMemo, useReducer } from 'react'
import AddBankAccountModal from '../atomic-components/organisms/addBankAccountModal'
import { useTranslation } from './translationProvider'
import { isMobileApp, sendMobileAppEvent } from '../utils/mobileAppFunctionality'

const CreateBankAccountContext = createContext()

const ActionTypes = {
  OPEN_CREATE_BANK_ACCOUNT: 'OPEN_CREATE_BANK_ACCOUNT',
  CLOSE_CREATE_BANK_ACCOUNT: 'CLOSE_CREATE_BANK_ACCOUNT',
}

const createBankAccountReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.OPEN_CREATE_BANK_ACCOUNT: {
      return {
        ...state,
        isOpen: true,
      }
    }
    case ActionTypes.CLOSE_CREATE_BANK_ACCOUNT: {
      return {
        ...state,
        isOpen: false,
      }
    }

    default:
      throw new Error(`Action is not supported: ${action.type}`)
  }
}

const initialState = {
  isOpen: false,
}

export const CreateBankAccountProvider = (props) => {
  const { translate } = useTranslation()
  const [state, dispatch] = useReducer(createBankAccountReducer, initialState)

  const value = useMemo(() => ({ state, dispatch }), [state])

  return (
    <CreateBankAccountContext.Provider value={value} {...props}>
      {state.isOpen && <AddBankAccountModal translate={translate} />}

      {props.children}
    </CreateBankAccountContext.Provider>
  )
}

export const useCreateBankAccountContext = () => {
  const context = useContext(CreateBankAccountContext)

  if (!context) return {}

  const { state, dispatch } = context
  const { isOpen } = state

  const handleIsMobileApp = () => {
    if (isMobileApp()) {
      sendMobileAppEvent('createBankAccount')
    }

    return isMobileApp()
  }

  const openCreateBankAccount = () => {
    if(handleIsMobileApp()) {
      return
    }

    dispatch({ type: ActionTypes.OPEN_CREATE_BANK_ACCOUNT })
  }

  const closeCreateBankAccount = () => {
    dispatch({ type: ActionTypes.CLOSE_CREATE_BANK_ACCOUNT })
  }

  return { openCreateBankAccount, closeCreateBankAccount, isOpen }
}
