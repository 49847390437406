import React, { useEffect } from 'react'
import { useTranslation } from '../../../context/translationProvider'
import { StyledText } from './style'

export const AllowLocation = () => {
  const { translate } = useTranslation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer?.push({
        event: 'location_prompt'
      })
    }
  }, [])

  return (
    <>
      <StyledText>{translate('geolocation.allowLocation')}</StyledText>
      <StyledText style={{ marginTop: '75px' }}>
        {translate('geolocation.promptWillAppear')}
      </StyledText>
    </>
  )
}
