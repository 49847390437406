import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Field } from '../../molecules/field';
import { BankCard } from '../../molecules/BankCard';
import { useDebounce } from '../../../hooks/useDebounce';
import { StyledBankListWrapper, StyledListHeader } from './styles';

export const SearchableSelect = ({
  options,
  onSelect,
  initialList,
  translate
}) => {
  const [list, setList] = useState([]);

  const handleFilterChange = (filter) => {
    if (filter) {
      const filteredList = options.filter((option) => {
        return option.name.toLowerCase().includes(filter.toLowerCase());
      });

      return setList(filteredList);
    }

    if (initialList) return setList(initialList);

    setList(options);
  }

  const debouncedFilterChange = useDebounce(handleFilterChange, 400)
  const listTitle = () => {
    if (list.length === 0) return 'search.noResults';
    if (list.length === initialList.length) return 
    'addBankAccount.popularBanks';
    return 'addBankAccount.SearchedResults';
  }

  useEffect(() => {
    handleFilterChange(null);
  }, [options, initialList]);
  
  return (
    <>
      <Field 
        type="text"
        label={translate('payments.bankName')}
        description={translate('addBankAccount.SelectBankTip')}
        descriptionTheme={'tip'}
        onChange={(e) => debouncedFilterChange(e.target.value)}
      />
      
      <StyledListHeader>{translate(listTitle())}</StyledListHeader>

      <StyledBankListWrapper>
        {list.map((option, index) => (
          <BankCard
            key={index}
            bank={option}
            onClick={onSelect}
          />
        ))}
      </StyledBankListWrapper>
    </>
  );
}

SearchableSelect.defaultProps = {
  options: [],
  onSelect: () => {},
  initialList: '',
  translate: (text) => text,
}

SearchableSelect.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func,
  initialList: PropTypes.array,
  translate: PropTypes.func,
}
