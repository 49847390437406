import React, { useEffect } from 'react'
import {
  GrowthBook,
  GrowthBookProvider as Provider
} from '@growthbook/growthbook-react'

import { EnvConfig } from '../config/EnvConfig'
import { detectBrowser } from '../utils/generic'
import { countryCheckTranslations } from '../adapters/auth'
import { getOrCreateBrowserId } from '../utils/growthbook'

export const growthbook = new GrowthBook({
  apiHost: EnvConfig.GATSBY_GROWTHBOOK_URL,
  clientKey: EnvConfig.GATSBY_GROWTHBOOK_KEY,
  enableDevMode: EnvConfig.GATSBY_ENV === 'dev',
})

growthbook.init({
  autoRefresh: true,
  timeout: 2000,
})

export const GrowthBookProvider = ({ children }) => {
  const load = async () => {
    const { data: { name: country } } = await countryCheckTranslations()

    growthbook.setAttributes({
      id: getOrCreateBrowserId(),
      browser: detectBrowser(),
      pathname: window.location.pathname,
      url: window.location.href,
      userAgent: navigator.userAgent,
      country,
    })
  }

  useEffect(() => load(), [])

  return (
    <Provider growthbook={growthbook}>
      {children}
    </Provider>
  )
}
