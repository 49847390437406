import styled from 'styled-components';
import { themeMedia } from '../../../../../styles/theme';
import { brandConfig } from '../../../../../config/brandConfig';

export const StyledTitle = styled.p`
  font-size: 30px;
  font-weight: 800;
  font-style: italic;
  font-family: ${brandConfig.fonts.text.h1.family};
  text-align: left;
  margin: 0;
  margin-bottom: 30px;

  ${themeMedia.desktop`
      font-size: 50px;
      margin-bottom: 40px;
  `}
`;

export const StyledText = styled.p`
  margin: 0;
  font-size: 17px;
  text-align: left;
  margin-bottom: 40px;

  ${themeMedia.desktop`
      margin-bottom: 50px;
  `}
`
