import { useFeatureValue } from '@growthbook/growthbook-react'

const locale = process.env.GATSBY_INTL_LOCALE

export const useGroowthbookLocaleFeature = (flag) => {
  const value = useFeatureValue(flag)?.[locale]
  return value
}

export const useGrowthbookFeature = (feature, fallback) => {
  const value = useFeatureValue(feature, fallback)
  return value
}
