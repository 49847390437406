import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'
import SuccessIcon from '../../../images/success_icon.svg'
import WhiteTick from '../../../images/white_tick.svg'
import { brandConfig } from '../../../config/brandConfig'

export const StyledCheckboxWrapper = styled.div`
  font-size: ${brandConfig.fonts.text.small.desktop};
  margin: 0 0 1rem 0;
  position: relative;
  display: flex;

  label {
    padding: 0 0 0 1rem;
    width: 100%;
  }

  a {
     color: ${({ $linkColor }) => {
        if($linkColor === 'black') {
          return themeStyles.colors.black.main;
        }

        return themeStyles.colors.accentColors.secondary.main;
      }} !important;
   }
`

export const StyledCheckbox = styled.input`
  display: block;
  cursor: pointer;
  height: 32px;
  width: 32px;
  appearance: none;

  &:before {
    content: '';
    display: block;
    position: absolute;

    height: 32px;
    width: 32px;
    border-radius: 6px;
    box-sizing: border-box;

    background-color: ${themeStyles.colors.lightGreys[1].main};
    border: 3px solid ${themeStyles.colors.lightGreys[3].main};
    ${(props) =>
      props.invalid && `border: 3px solid ${themeStyles.colors.error.main};`}
  }

  &:checked {
    &:before {
      background-color: ${themeStyles.colors.white.main};
      border: 3px solid ${themeStyles.colors.accentColors.primary.main};
      background-image: url('${SuccessIcon}');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
    }
  }
  ${(props) =>
    props?.theme === 'dark' &&
    css`
      &:before {
        background-color: ${themeStyles.colors.darkGreys[3].main};
        border: 3px solid ${themeStyles.colors.white.main};
      }
      &:checked {
        &:before {
          background-color: ${themeStyles.colors.darkGreys[3].main};
          border: 3px solid ${themeStyles.colors.white.main};
          background-image: url('${WhiteTick}');
          background-size: 18px;
        }
      }
    `}
`
