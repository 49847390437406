import React from 'react'
import PropTypes from 'prop-types'
import SimplePopUp from '../../atoms/simplePopUp'
import {
  ResultWrapper,
  StyledHeaderIcon,
  StyledMainDescription,
  StyledMainTitle,
  VerifiedIcon,
} from './style'
import VerifiedIconImage from '../../../images/icon_verified.svg'

const SuccessWizard = (props) => {
  const { title, onClose, description } = props
  return (
    <SimplePopUp onClose={onClose}>
      <ResultWrapper>
        <StyledHeaderIcon>
          <VerifiedIcon src={VerifiedIconImage} alt="" />
        </StyledHeaderIcon>
        <StyledMainTitle>{title}</StyledMainTitle>
        <StyledMainDescription>{description}</StyledMainDescription>
      </ResultWrapper>
    </SimplePopUp>
  )
}

SuccessWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
}

export default SuccessWizard
