import React from 'react'
import PropTypes from 'prop-types'
import { StyledDeleteButton } from './styles'
import { DeleteIcon } from '../DeleteIcon'
import { useTranslation } from '../../../context/translationProvider'

export const DeleteButton = ({ onDelete }) => {
  const { translate } = useTranslation()
  return (
    <StyledDeleteButton type="button" onClick={onDelete}>
      <DeleteIcon />
      {translate('payments.bankAccountKeys.delete')}
    </StyledDeleteButton>
  )
}

DeleteButton.propTypes = {
  onDelete: PropTypes.func,
}
