export const mapCountryCheckToAuthDataCheck = (countryCheck) => {
  if (!countryCheck) getAuthDataCheckDefaultValues()

  return {
    countryBlocked: !countryCheck.active,
    loginBlocked: countryCheck.login_blocked,
    registrationBlocked: !!countryCheck?.registration_blocked,
    blockedPageTranslations: countryCheck?.translations ?? [],
    accessCountry: countryCheck?.name,
  }
}

export const getAuthDataCheckDefaultValues = () => {
  return {
    countryBlocked: false,
    loginBlocked: false,
    registrationBlocked: false,
    blockedPageTranslations: [],
    accessCountry: undefined,
  }
}