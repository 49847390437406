import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

export const Box = styled.div`
  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `}
  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}

    ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

    ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}

    ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

    ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: ${paddingTop};
    `}

    ${({ paddingBottom }) =>
    paddingBottom &&
    css`
      padding-bottom: ${paddingBottom};
    `}

    ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: ${paddingLeft};
    `}

    ${({ paddingRight }) =>
    paddingRight &&
    css`
      padding-right: ${paddingRight};
    `}

    ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

    ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}

    ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}

    ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}

    ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: ${marginRight};
    `}

    ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}
`

export const InfoBox = styled.div`
  ${Box};
  display: block;
  border-radius: 8px;
  padding: 0.5em;
  background: ${themeStyles.colors.lightGreys[1].main};

  ${themeMedia.desktop`
        padding: 1em;
    `}

  ${({ theme }) =>
    theme === 'success' &&
    css`
      background: ${themeStyles.colors.defaultColors.green[50]};
    `}
    
    ${({ theme }) =>
    theme === 'lightInfo' &&
    css`
      background: ${themeStyles.colors.info.lightInfo2.main};
    `}
    
    ${({ theme }) =>
    theme === 'info' &&
    css`
      background: ${themeStyles.colors.info.main};
    `}
    
    ${({ theme }) =>
    theme === 'white' &&
    css`
      background: ${themeStyles.colors.white.main};
    `}

    ${({ theme }) =>
    theme === 'outlined' &&
    css`
      background: ${themeStyles.colors.white.main};
      border: 2px solid ${themeStyles.colors.lightGreys[2].main};
    `}

    ${({ hideBorderRadius }) =>
    hideBorderRadius &&
    css`
      border-radius: 0px;
    `}
`
