import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '../../atoms/box'
import { themeStyles } from '../../../styles/theme'
import { StyledBasicAlertImage, StyledBasicAlertText, Wrapper } from './styles'
import useDeviceDetect from '../../../hooks/useDeviceDetect'

const BasicAlert = ({
  mobileIcon,
  desktopIcon,
  icon,
  description,
  backgroundColor = themeStyles.colors.white.main,
  borderColor = themeStyles.colors.lightGreys[1].main,
  borderRadius = '6px',
  padding,
  children,
  className,
  imageProps = {},
  textProps = {},
}) => {
  const { isMobile } = useDeviceDetect()

  const imageSource = icon || (isMobile ? mobileIcon : desktopIcon)

  return (
    <Box
      display="flex"
      width="100%"
      className={className}
      style={{
        background: backgroundColor,
        border: `2px solid ${borderColor}`,
        borderRadius,
        padding,
      }}
    >
      <Wrapper>
        {imageSource && (
          <StyledBasicAlertImage src={imageSource} alt="" {...imageProps} />
        )}
        {description && (
          <StyledBasicAlertText {...textProps}>
            {description}
          </StyledBasicAlertText>
        )}
        {children}
      </Wrapper>
    </Box>
  )
}

BasicAlert.propTypes = {
  mobileIcon: PropTypes.string,
  desktopIcon: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderRadius: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  imageProps: PropTypes.object,
  textProps: PropTypes.object,
}

export default BasicAlert
