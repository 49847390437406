import { coreApi } from '../adapters/base'

const defaultOptions = {
  hasNestedData: true,
  hasValid: false,
  hasNoData: false,
  api: coreApi,
}

// Helper functions
const generateGoodResponse = (response, options) => {
  const { hasNestedData, hasValid, hasNoData } = options
  if (hasNoData) return { ok: true }

  const { data } = response
  const ok = hasValid ? data?.valid : true

  if (hasNestedData) {
    return { ok, data: data.data }
  } else {
    return { ok, data }
  }
}

// TODO: Review why we always send error?.response?.data
// Its weird because response is always undefined
const generateBadResponse = (error) => {
  return { ok: false, error: error?.response?.data, errorDetails: error }
}

// The actual API functions (GET, POST, PUT, PATCH, DELETE)
export const apiGET = async (url, body, passedOptions = {}) => {
  const options = { ...defaultOptions, ...passedOptions }
  try {
    const response = await options.api.get(url, {
      headers: options.headers,
      ...body,
    })
    return generateGoodResponse(response, options)
  } catch (error) {
    return generateBadResponse(error)
  }
}

export const apiPOST = async (url, body, passedOptions) => {
  const options = { ...defaultOptions, ...passedOptions }
  try {
    const response = await options.api.post(url, body)
    return generateGoodResponse(response, options)
  } catch (error) {
    return generateBadResponse(error)
  }
}

export const apiPUT = async (url, body, passedOptions) => {
  const options = { ...defaultOptions, ...passedOptions }
  try {
    const response = await options.api.put(url, body)
    return generateGoodResponse(response, options)
  } catch (error) {
    return generateBadResponse(error)
  }
}

export const apiPATCH = async (url, body, passedOptions) => {
  const options = { ...defaultOptions, ...passedOptions }
  try {
    const response = await options.api.patch(url, body)
    return generateGoodResponse(response, options)
  } catch (error) {
    return generateBadResponse(error)
  }
}

export const apiDELETE = async (url, body, passedOptions) => {
  const options = { ...defaultOptions, ...passedOptions }
  try {
    const response = await options.api.delete(url, body)
    return generateGoodResponse(response, options)
  } catch (error) {
    return generateBadResponse(error)
  }
}

export const fetcher = (url) => {
  return apiGET(url).then(response => response.data)
}
