import styled from 'styled-components'

import { themeMedia } from '../../../styles/theme'

export const StyledFormModalWrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  height: 100%;
  padding: 1rem;
  padding-top: 35px;

  button {
    margin-top: auto;
  }

  ${themeMedia.desktop`
    padding-top: 50px;
  `}

  div.buttons-wrapper {
    padding: 0 4.5rem;
  }
`

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 550px;
  align-items: center;
  justify-content: center;
  width: 100%;

  > * {
    text-align: center;
  }

  h2 {
    font-size: 22px;
    text-align: center;
  }
`
