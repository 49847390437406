import React from 'react'
import PropTypes from 'prop-types'
import { StyledCheckbox, StyledCheckboxWrapper } from './styles'

const Checkbox = (props) => {
  const { showError, required, children, formProperties, theme, $linkColor,  ...rest } =
    props

  return (
    <StyledCheckboxWrapper $linkColor={$linkColor}>
      <StyledCheckbox
        type="checkbox"
        required={required}
        {...formProperties}
        {...rest}
        invalid={showError}
        theme={theme}
      />
      <label htmlFor={formProperties.name}>{children}</label>
    </StyledCheckboxWrapper>
  )
}

Checkbox.defaultProps = {
  showError: false,
  required: false,
  children: <></>,
  formProperties: {},
  $linkColor: 'primary',
}

Checkbox.propTypes = {
  showError: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.any,
  formProperties: PropTypes.object.isRequired,
  $linkColor: PropTypes.string,
}

export { Checkbox }
