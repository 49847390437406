import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Text, Title } from '../../../atoms/text'
import { Image } from '../../../atoms/image'
import { StyledGenericContentWrapper } from '../styles'
import { Button } from '../../../atoms/button'
import { Spinner } from '../../../atoms/spinner'
import { If } from '../../../atoms/if'

const RegisterChangeValueContent = (props) => {
  const {
    onSubmit,
    icon,
    ctaText,
    title,
    description,
    renderField,
    formSubmitIsDisabled 
  } = props

  const [loading, setLoading] = useState(false)

  const onCtaClick = async () => {
    setLoading(true)
    await onSubmit(setLoading)
    setLoading(false)
  }

  return (
    <StyledGenericContentWrapper>
      <Title level={2}>{title}</Title>
      <Image url={icon} />
      <Text>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </Text>
      {renderField()}

      <Button
        id="resend"
        expand
        disabled={loading || formSubmitIsDisabled}
        onClick={onCtaClick}
        type="button"
      >
        <If
          condition={!loading}
          render={() => ctaText}
          renderElse={() => <Spinner size={'1.5em'} />}
        />
      </Button>
    </StyledGenericContentWrapper>
  )
}

RegisterChangeValueContent.defaultProps = {}

RegisterChangeValueContent.propTypes = {
  onSubmit: PropTypes.func,
  icon: PropTypes.any,
  ctaText: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  renderField: PropTypes.func,
  formSubmitIsDisabled: PropTypes.bool,
}

export { RegisterChangeValueContent }
