import styled  from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  max-height: 70px;
  padding: 0 19px;
  border-radius: 8px;
  background-color: ${themeStyles.colors.accentColors.secondary.main};
  gap: 10px;

  ${themeMedia.maxMobile`
    min-height: 55px;
    max-height: 55px;
    padding: 0 11px;
  `}

  ${themeMedia.desktop`
     gap: 20px;
  `}
`

export const StyledImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  
  img {
    height: 28px;
  }

  ${themeMedia.desktop`
    gap: 10px;
    img {
      height: 32px;
    }
  `}
`

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    font-weight: bold;
    line-height: 12px;
    font-size: 12px;
    margin: 0;
  }

  ${themeMedia.desktop`
    p {
      line-height: 15px;
      font-size: 15px;
    }
  `}

`