import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { apiGET } from '../utils/adapterFunctions'
import { DATE_FORMATS } from '../utils/constants'

dayjs.extend(utc)

export const getNetDeposits = async ({
  dateFrom,
  dateTo,
  status = 'OK,ACCEPTED_ON_PLATFORM',
}) => {
  const params = {
    created_at_from: dayjs(dateFrom).format(DATE_FORMATS.default),
    created_at_to: dayjs(dateTo).format(DATE_FORMATS.default),
    status,
  }

  return await apiGET('/payments/totals', { params })
}

export const getSportsTransactions = async (
  dateFrom,
  dateTo,
  page,
  size,
  extraParams = {}
) => {
  const params = {
    pageNumber: page,
    pageSize: size,
    sort: 'createdAt,desc',
    created_at_from: dayjs.utc(dateFrom).format(DATE_FORMATS.default),
    created_at_to: dayjs.utc(dateTo).format(DATE_FORMATS.default),
    ...extraParams,
  }
  return await apiGET('/sportsbook/v2/list', { params })
}

export const getTransactions = async (
  transactionType,
  playerId,
  dateFrom,
  dateTo,
  page,
  size
) => {
  try {
    let statuses =
      'OK,DECLINED,PENDING,ACCEPTED_ON_PLATFORM,DECLINED_ON_PLATFORM,BC_MIGRATION'
    if (
      (Array.isArray(transactionType) &&
        transactionType.includes('WITHDRAWAL')) ||
      transactionType === 'WITHDRAWAL'
    ) {
      statuses += ',CREATED,CANCELED_BY_USER,ON_HOLD'
    }

    const params = new URLSearchParams({
      type: transactionType, // 'DEPOSIT' | 'WITHDRAWAL'
      pageNumber: page,
      pageSize: size,
      sort: 'createdAt,desc',
      user_id: playerId,
      created_at_from: dayjs.utc(dateFrom).format(DATE_FORMATS.default),
      created_at_to: dayjs.utc(dateTo).format(DATE_FORMATS.default),
      statuses: statuses,
    })

    return await apiGET('/payments', { params })
  } catch (error) {
    return { ok: false, error: error?.response?.data }
  }
}

export const getSessionStats = async () => {
  return await apiGET('/player-session-overview', null, {
    hasNestedData: false,
  })
}

export const getGameplayStats = async (dateFrom, dateTo) => {
  const params = {
    from: dayjs.utc(dateFrom).format(DATE_FORMATS.default_with_miliseconds),
    to: dayjs.utc(dateTo).format(DATE_FORMATS.default_with_miliseconds),
  }

  return await apiGET(
    '/casino/api/player-stats/totals',
    { params },
    { hasNestedData: false }
  )
}

export const getPlayerBankAccountStatus = async () => {
  return await apiGET('/player/bank-accounts', { hasNestedData: false })
}

export const getDepositInfo = async () => {
  return await apiGET('/user-activity-fact/deposit-info')
}
