import styled from 'styled-components'

export const StyledCircleStepContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#000' : '#CCCCCC')};
  transition: background-color 0.3s ease;
`
