import styled from "styled-components"
import { brandConfig } from "../../../config/brandConfig"
import { themeMedia, themeStyles } from "../../../styles/theme"


export const CookieSettingsWrapper = styled.div`
  padding: 0 1rem;
  ${themeMedia.tablet`
    padding: 0;
  `}
`

export const CookieSettingsH1 = styled.h1`
  font-size: 1.6em;
  font-weight: 600;
  margin: 2rem 0 1rem 0;
`

export const CookieSettingsP = styled.p`
  font-size: ${brandConfig.fonts.text.paragraph.size.desktop};
  line-height: 1.2em;
  margin: 0.5rem 0 1rem 0;

  a {
    color: ${themeStyles.colors.black.main};
  }
`

export const CookieSettingsCheckboxWrapper = styled.div`
  background-color: ${themeStyles.colors.lightGreys[1].main};
  border-radius: 6px;
  padding: 0.3rem;
  margin-bottom: 1rem;

  > div {
    align-items: center;
    margin: 0 0 0 0.4rem;
  }

  ${({ disabled }) =>
    disabled &&
    `
    > div {
      opacity: 0.5;
      pointer-events: none;
    }
  `}
`

export const CookieSettingsButtonWrapper = styled.div`
  padding: 1rem 0;
  text-align: center;
`