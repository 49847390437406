import styled from 'styled-components';
import { themeMedia } from '../../../styles/theme';
import { brandConfig } from '../../../config/brandConfig';

export const StyledContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  border: 2px solid #e1e1e1;
  border-radius: 6px;
  padding: 10px 16.5px;

  display: flex;
  gap: 15px;

  ${themeMedia.desktop`
    padding: 10px 14.5px;
  `}
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  a {
    color: ${brandConfig.colors.black.main};
    text-decoration: underline;
  }
`