import { useImmutableQuery } from '../useQuery'

export const useCountryCheckQuery = () => {
  return useImmutableQuery('/country/check')
}

export const useCountryRegisterQuery = () => {
  return useImmutableQuery('/country/register')
}

export const useCountryRegistrationDropdownQuery = () => {
  return useImmutableQuery('/country/registration-dropdown')
}
