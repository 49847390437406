import PropTypes from 'prop-types'
import React, { useEffect, useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { usePaymentsContext } from '../../../context/paymentProvider'
import { AuthContext } from '../../../context/authProvider'
import { useGroowthbookLocaleFeature } from '../../../hooks/useGrowthbook'
import storageService from '../../../services/storageService'
import { useTranslation } from '../../../context/translationProvider'
import { usePaymentDrawerContext } from '../../../context/paymentDrawerProvider'
import { KycStatus, PaymentTypes } from '../../../utils/constants'
import { useCreateBankAccountContext } from '../../../context/createBankAccountProvider'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import { useDepositApiData } from '../../../hooks/graphqlStaticQuery/useDepositApiData'

import { PendingWithdrawal } from '../../molecules/pendingWithdrawal'
import { CpfModalContent } from '../../molecules/cpfModalContent'
import { CpfInvalidContent } from '../../molecules/cpfInvalidContent'
import { PendingCpf } from '../../molecules/pendingCpf'
import { DepositContent } from '../../molecules/depositContent'

import { AnimatedTextFallback } from '../../atoms/animatedFallbacks'
import { CapabilitiesDisclaimer } from '../../atoms/capabilitiesDisclaimer'
import { Button } from '../../atoms/button'
import { Drawer } from '../../atoms/drawer'
import { If } from '../../atoms/if'
import { Text } from '../../atoms/text'
import { toast } from '../../atoms/toast'
import {
  resendActivationEmail,
  getUserCapabilitiesAndNationalIdStatus,
  getRegisteredBankAccounts,
} from '../../../adapters/user'
import {
  getPaymentIQData,
  getPendingWithdrawals,
  getDepositCapabilites,
  getPaymentDepositLobby,
  getCryptoCoins,
} from '../../../adapters/payments'

import TimesWhiteIcon from '../../../images/icon_times_white.svg'
import { themeStyles } from '../../../styles/theme'
import { getWebsiteUrl } from '../../../utils/getWebsiteUrl'
import { getPlayerBankAccounts } from '../../../utils/getPlayerBankAccounts'
import { useSessionStorage } from '../../../hooks/useSessionStorage'
import { cookiePrefix } from '../../../utils/cookies'

const Message = styled.div`
  text-align: center;
  padding: 2rem 0;
`

const StyledDrawerWrapper = styled.div`
  z-index: 99999; // Needs to be higher than navbar
  position: relative;
`

const PaymentIQWrapper = styled.div`
  min-height: ${({ minHeight }) => `${minHeight}px`};
  margin-top: 48px;

  iframe {
    width: 100%;
    min-height: ${({ minHeight }) => `${minHeight}px`};
    height: 100%;
  }
`

const customHeaderStyles = css`
  background: ${themeStyles.colors.black.main};
  color: ${themeStyles.colors.white.main};

  button {
    background-image: url('${TimesWhiteIcon}');
    top: 50%;
    right: 18px;
  }
`

const Deposit = ({ isPage, initialData }) => {
  const { isOpen, close: onCloseDrawer } = usePaymentDrawerContext()
  const { isMobile } = useDeviceDetect()
  const { translate } = useTranslation()
  const [user, setUser] = useState(storageService.getUser())
  const depositLobbyApiData = useDepositApiData()

  const {
    updateUser,
    getUserWallet,
    isLoggedIn,
    user: loggedUser,
  } = useContext(AuthContext)
  const [loadingContent, setLoadingContent] = useState(true)

  const { openCreateBankAccount } = useCreateBankAccountContext()
  const [pendingWithdrawals, setPendingWithdrawals] = useState([])
  const [pendingWithdrawalsTotals, setPendingWithdrawalsTotals] = useState(null)
  const [cpfReminder, setCpfRemider] = useState(false)
  const { getPaymentsByType, isRollbackWithdrawalAllowed } =
    usePaymentsContext()
  const [, setProcessingCPF] = useState(false)
  const [isDepositAllowed, setIsDepositAllowed] = useState(false)
  const [blockedDepositReason, setBlockedDepositReason] = useState('')
  const [, setCPFIsRejectedOrPending] = useState(false)
  const [isCPFRequired, setCPFIsRequired] = useState(false)
  const [isBrazilian, setIsBrazilian] = useState(false)
  const paymentIQFeature = useGroowthbookLocaleFeature('payment_iq')
  const [paymentIQURL, setPaymentIQURL] = useState('')
  const [paymentIQHeight, setPaymentIQHeight] = useState()
  const [depositLobbyData, setDepositLobbyData] = useState(null)
  const [cryptoCoins, setCryptoCoins] = useState([])
  const [depositsPrerequisitesReady, setDepositsPrerequisitesReady] =
    useState(false)
  const [usableBankAccounts, setUsableBankAccounts] = useState([])
  const isUserUsingBRL = user?.wallet?.currency?.short_code === 'BRL'
  const bankAccountsRegulationFlow = useFeatureIsOn(
    'fe_igp_bank_accounts_regulation_flow'
  )

  const { setSessionValue } = useSessionStorage()

  const isPaymentIQEnabled = paymentIQFeature && !isUserUsingBRL
  const websiteURL = getWebsiteUrl()

  const getCpfReminder = () => {
    const reminder = storageService.getValue('cpf:reminder') === 'true'
    setCpfRemider(reminder)
  }

  const submitCPF = () => {
    getCpfReminder()
    setProcessingCPF(true)
    setCPFIsRequired(false)
    setCPFIsRejectedOrPending(false)
  }

  const proceedToDeposit = () => {
    setPendingWithdrawals([])
    setPendingWithdrawalsTotals(null)
  }

  const resendEmailActivation = async () => {
    const response = await resendActivationEmail(
      user?.email,
      `${websiteURL}activate/`
    )

    if (response.ok) toast.success(translate('account.resendEmailSuccess'))
  }

  const handleDepositCapabilites = async () => {
    setLoadingContent(true)
    const { data } = await getDepositCapabilites()

    setIsDepositAllowed(!data?.block)
    setBlockedDepositReason(data?.reason)

    if (user?.country?.id === 31) {
      setIsBrazilian(true)
    } else {
      setIsBrazilian(false)
    }

    setLoadingContent(false)
  }

  const getDepositLobby = async () => {
    const { data } = await getPaymentDepositLobby()

    if (!data) return

    const methods = data?.methods?.sort((a, b) => {
      if (a.orderNumber < b.orderNumber) {
        return -1
      }
      if (a.orderNumber > b.orderNumber) {
        return 1
      }
      return 0
    })

    data.methods = methods
    setDepositLobbyData(data)
  }

  const fetchCryptoCoins = async () => {
    const { ok, data } = await getCryptoCoins()
    if (ok) setCryptoCoins(data)
  }

  const fetchPendingWithdrawals = async () => {
    const { data } = await getPendingWithdrawals()

    setPendingWithdrawals(data.transactions.content)
    setPendingWithdrawalsTotals(data.totals)
  }

  const listenPaymentIQ = (event) => {
    if (event?.data?.eventType === 'TX_SUCCESS') {
      getUserWallet()
    }

    if (event?.data?.eventType === 'APP_SET_HEIGHT') {
      setPaymentIQHeight(event?.data?.payload?.height)
    }
  }

  const pendingWithdrawalsClose = () => {
    fetchPendingWithdrawals()
    onCloseDrawer()
  }

  useEffect(() => {
    if (isOpen) {
      const getMethods = async () => {
        await getPaymentsByType(PaymentTypes.DEPOSIT)
      }
      getMethods()
      getCpfReminder()
    }
  }, [isOpen, isLoggedIn])

  useEffect(() => {
    const storedUser = storageService.getUser()

    const getUserStatuses = async () => {
      setLoadingContent(true)
      const response = await getUserCapabilitiesAndNationalIdStatus()

      if (response.ok) {
        const { deposit, national_id_status } = response.data.data
        const updatedUser = {
          ...storedUser,
          national_id_status,
          user_capabilities: {
            ...storedUser?.user_capabilities,
            deposit,
          },
        }

        setUser(updatedUser)
        updateUser(updatedUser)
      }
      setLoadingContent(false)
    }

    if (
      storedUser?.national_id_status === 'PROCESSING' ||
      storedUser?.national_id_status === 'REQUIRED'
    ) {
      getUserStatuses()
      return
    }

    setLoadingContent(false)
  }, [])

  useEffect(() => {
    if (!user || !loggedUser || (!isOpen && !isPage)) return

    getDepositLobby()
    handleDepositCapabilites()
    fetchCryptoCoins()
  }, [user, isOpen])

  useEffect(() => {
    if (!isRollbackWithdrawalAllowed) return
    fetchPendingWithdrawals()
  }, [isRollbackWithdrawalAllowed])

  useEffect(() => {
    const getPaymentIQURL = async () => {
      const { ok, data } = await getPaymentIQData('deposit')
      if (ok) setPaymentIQURL(data?.url)
    }

    if (isPaymentIQEnabled) {
      getPaymentIQURL()
    }
  }, [isPaymentIQEnabled])

  useEffect(() => {
    if (isPaymentIQEnabled && getUserWallet) {
      window.addEventListener('message', listenPaymentIQ, false)

      return () => {
        window.removeEventListener('message', listenPaymentIQ)
      }
    }
  }, [isPaymentIQEnabled, getUserWallet])

  async function fetchBankAccounts() {
    try {
      const result = await getRegisteredBankAccounts()
      return result.data
    } catch (error) {
      return toast.success(translate('common.unexpectedError'))
    }
  }

  const handleDepositPrerequisites = async () => {
    const { usableBankAccounts: bankAccountList } =
      await getPlayerBankAccounts()
    setUsableBankAccounts(bankAccountList || [])

    const pendingLivenessAccount = bankAccountList.find(
      (account) => account.status === KycStatus.WAITING_LIVENESS
    )

    if(pendingLivenessAccount) {
      setSessionValue(
        `${cookiePrefix}pendingLivenessAccount`, 
        pendingLivenessAccount
      )
    }

    if ((isOpen || isPage) && !depositsPrerequisitesReady) {
      try {
        const bankAccounts = await fetchBankAccounts()
        const hasPendingAccounts =
          bankAccounts?.inactiveAccounts.filter(
            (account) => account.status === KycStatus.PENDING
          ).length > 0
        const hasActiveAccounts =
          bankAccounts?.activeAccounts?.length > 0 || hasPendingAccounts

        if (!hasActiveAccounts || pendingLivenessAccount) {
          onCloseDrawer()
          openCreateBankAccount()
        } else {
          setDepositsPrerequisitesReady(true)
        }
      } catch (error) {
        return toast.success(translate('common.unexpectedError'))
      }
    }
  }

  useEffect(() => {
    if (isOpen && !bankAccountsRegulationFlow) {
      setDepositsPrerequisitesReady(true)
    } else if (isOpen || isPage) {
      handleDepositPrerequisites()
    } 
  }, [isOpen, bankAccountsRegulationFlow, ])

  const content = () => (
    <If
      condition={loadingContent}
      render={() => (
        <>
          <Text>
            <AnimatedTextFallback block level={4} theme="light" />
          </Text>
          <Text>
            <AnimatedTextFallback block level={4} theme="light" />
          </Text>
        </>
      )}
      renderElse={() => (
        <>
          {!isDepositAllowed && (
            <>
              {(blockedDepositReason === 'NATIONAL_ID_INVALID' ||
                user?.national_id_status === 'REPROVED') && (
                <CpfInvalidContent onClose={onCloseDrawer} />
              )}
              {blockedDepositReason === 'NATIONAL_ID_PROCESSING' && (
                <PendingCpf color={themeStyles.colors.black.main} />
              )}
              {blockedDepositReason !== 'NATIONAL_ID_INVALID' &&
                blockedDepositReason !== 'NATIONAL_ID_PROCESSING' && (
                  <CapabilitiesDisclaimer
                    color={themeStyles.colors.black.main}
                    onClose={onCloseDrawer}
                  />
                )}
            </>
          )}

          {isBrazilian &&
            cpfReminder &&
            isCPFRequired &&
            !user?.override_caf && (
              <CpfModalContent
                isDrawer
                onClose={onCloseDrawer}
                onSubmit={submitCPF}
              />
            )}

          {isDepositAllowed && user?.national_id && (
            <>
              {!isPaymentIQEnabled &&
                pendingWithdrawals.length === 0 &&
                !!depositLobbyData?.methods && (
                  <DepositContent
                    initialData={initialData}
                    depositClose={onCloseDrawer}
                    user={user}
                    depositLobbyData={depositLobbyData}
                    depositLobbyCMSData={depositLobbyApiData}
                    cryptoCoins={cryptoCoins}
                    isMobile={isMobile}
                    isPage={isPage}
                    usableBankAccounts={usableBankAccounts}
                  />
                )}

              {isPaymentIQEnabled &&
                pendingWithdrawals.length === 0 &&
                paymentIQURL && (
                  <PaymentIQWrapper minHeight={paymentIQHeight}>
                    <iframe src={paymentIQURL} />
                  </PaymentIQWrapper>
                )}

              {pendingWithdrawals.length > 0 && isRollbackWithdrawalAllowed && (
                <PendingWithdrawal
                  user={user}
                  data={pendingWithdrawals}
                  totals={pendingWithdrawalsTotals}
                  depositClose={pendingWithdrawalsClose}
                  proceedToDeposit={proceedToDeposit}
                />
              )}

              {!depositLobbyData?.methods &&
                !isPaymentIQEnabled &&
                user?.user_status?.name?.toLowerCase() !== 'pending' && (
                  <Message>
                    {translate('account.paymentMethodsNotAvailable')?.replace(
                      '{0}',
                      user?.wallet?.currency?.symbol ||
                        user?.wallet?.currency.short_code
                    )}
                  </Message>
                )}

              {user?.user_status?.name?.toLowerCase() === 'pending' && (
                <Message>
                  {translate('account.not-verified')}
                  <Button
                    style={{ margin: '1rem 0' }}
                    onClick={resendEmailActivation}
                  >
                    {translate('account.resend-email')}
                  </Button>
                </Message>
              )}
            </>
          )}
        </>
      )}
    />
  )

  if (isPage) return content()

  return (
    (loggedUser?.accept_tc ||
      loggedUser?.national_id_status === 'PROCESSING') && (
      <StyledDrawerWrapper>
        <Drawer
          customWidth="377px"
          isMobile={isMobile}
          customHeaderStyles={isMobile ? customHeaderStyles : null}
          title={isMobile ? translate('payments.deposit.title') : null}
          isOpen={isOpen && depositsPrerequisitesReady}
          onClose={onCloseDrawer}
          id="deposit-modal"
        >
          {content()}
        </Drawer>
      </StyledDrawerWrapper>
    )
  )
}

Deposit.propTypes = {
  isPage: PropTypes.bool.isRequired,
  initialData: PropTypes.object.isRequired,
}

export { Deposit }
