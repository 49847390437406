import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Field } from '../../../molecules/field'
import { FieldDropdown } from '../../../molecules/fieldDropdown'

import { StyledCol, StyledRow } from '../styles'
import { validatePhoneNumber } from '../../../../helpers/validators'
import {
  BR_MOBILE_MASK,
  BR_RESET_MASK,
  INITIAL_MOBILE_MASK,
} from '../../../../helpers/mask'

const CombinedMobileField = (props) => {
  const {
    formProperties,
    setMobilePrefix,
    data,
    onInputFocus,
    onInputBlur,
    setStepsDone,
    REGISTRATION_STEP_IDS,
    REGISTRATION_STEPS,
  } = props
  const { phoneCodes, mobilePrefix } = data
  const { register, getValues, setValue, formState } = formProperties
  const { errors } = formState

  const [mobileMask, setMobileMask] = useState(INITIAL_MOBILE_MASK)
  const mobileVerificationStep = REGISTRATION_STEPS.findIndex(
    (step) => step.id === REGISTRATION_STEP_IDS.CONFIRM_PHONE
  )

  useEffect(() => {
    const mobileNumber = getValues('mobileNumber')
    if (!mobileNumber) return

    handleMobileMask({
      key: 'Custom',
      target: { value: getValues('mobileNumber') },
    })
  }, [])

  useEffect(() => {
    const nationality = getValues('nationality')

    if (!nationality || nationality === 31)  {
      setMobilePrefix('+55')
      setValue('mobilePrefix', '+55')
    }
  }, [phoneCodes])

  const handleMobileMask = (event, _mobilePrefix = mobilePrefix) => {
    if (_mobilePrefix === '+55') {
      const { key, target } = event
      const { value } = target

      if (!Number?.isNaN(Number(key)) || key === 'Custom') {
        setMobileMask(BR_MOBILE_MASK)
      } else if (key === 'Unidentified') {
        setMobileMask(BR_MOBILE_MASK)
      } else if (key === 'Backspace' && value?.length === 15) {
        setMobileMask(BR_RESET_MASK)
      }
    } else {
      setMobileMask(INITIAL_MOBILE_MASK)
    }
  }

  const onMobilePrefixChange = (e) => {
    const code = e.target.value
    setValue('mobilePrefix', code)
    setMobilePrefix(code)
    handleMobileMask({
      key: 'Custom',
      target: { value: getValues('mobileNumber') },
    }, code)

    undoStepsDoneForMobileVerification()
  }

  const undoStepsDoneForMobileVerification = () => {
    setStepsDone((state) => ({ ...state, [mobileVerificationStep]: false }))
  }

  return (
    <StyledRow>
      <StyledCol width="32%">
        <FieldDropdown
          autoCompleteOff
          errors={errors}
          fieldValue={getValues('mobilePrefix')}
          formProperties={register('mobilePrefix', { required: true })}
          onChange={onMobilePrefixChange}
          removeFirstOption
          // !Important: revert +385
          values={[...phoneCodes, '+385'].map((row, i) => (
            <option key={i} value={row}>
              {row}
            </option>
          ))}
          onFocus={onInputFocus}
          onBlur={() => onInputBlur('mobile_input_timer')}
        />
      </StyledCol>
      <StyledCol width="68%">
        <Field
          onKeyDown={handleMobileMask}
          mask={mobileMask}
          onChange={undoStepsDoneForMobileVerification}
          autoCompleteOff
          type="tel"
          errors={errors}
          onFocus={onInputFocus}
          onBlur={() => onInputBlur('mobile_input_timer')}
          defaultValue={getValues('mobileNumber')}
          formProperties={register('mobileNumber', {
            required: true,
            validate: (value) => validatePhoneNumber(value, mobilePrefix),
          })}
        />
      </StyledCol>
    </StyledRow>
  )
}

CombinedMobileField.propTypes = {
  formProperties: PropTypes.object,
  setMobilePrefix: PropTypes.func,
  data: PropTypes.shape({
    mobilePrefix: PropTypes.string,
    phoneCodes: PropTypes.array,
  }),
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  setStepsDone: PropTypes.func,
  REGISTRATION_STEP_IDS: PropTypes.object,
  REGISTRATION_STEPS: PropTypes.array,
}

export { CombinedMobileField }
