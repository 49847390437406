import React, { useEffect, useState, useContext, useRef } from 'react'
import { Modal } from '../../atoms/modal'
import useModal from '../../../hooks/useModal'
import locationTrackingIcon from './../../../images/icon_location_tracking.svg'

import { AllowLocation } from './../../atoms/geolocation/allowLocation'
import { WaitingLocation } from './../../atoms/geolocation/waitingLocation'
import { DeniedLocation } from '../../atoms/geolocation/deniedLocation'
import { AuthContext } from '../../../context/authProvider'
import { TopBannerLocation } from '../../atoms/geolocation/topBannerLocation'
import { useTranslation } from '../../../context/translationProvider'
import Radar from 'radar-sdk-js'
import { useGrowthbookFeature } from '../../../hooks/useGrowthbook'
import { EnvConfig } from '../../../config/EnvConfig'
import { sendUserLocation } from '../../../adapters/user'
import { isiOS } from '../../../utils/mobileAppFunctionality'
import Clarity from '@microsoft/clarity'
import storageService from '../../../services/storageService'
import { StyledImage, StyledTitle, StyledWrapper } from './../../atoms/geolocation/style'
import { RadarErrorModal } from '../../atoms/geolocation/radarErrorModal'
import { useEventTracking } from '../../../context/eventTrackingProvider'
import { isBrowser } from '../../../utils/generic'

const GeolocationHandler = () => {
  const [userDecided, setUserDecided] = useState(false)
  const [permissionStatus, setPermissionStatus] = useState(null)
  const [radarResponse, setRadarResponse] = useState(null)
  const skipGeolocationApp = useGrowthbookFeature(
    'fe_igp_geolocation_skip_app',
    true
  )
  const geolocationInterval = useGrowthbookFeature(
    'fe_igp_geolocation_interval',
    30000
  )
  const enableHeatMap = useGrowthbookFeature('web_heatmap', false)
  const { translate } = useTranslation()
  const { trackEvent, EVENT_NAMES, EVENT_LOCATIONS } = useEventTracking()

  const {
    isLoggedIn,
    promptVisible,
    setPromptVisible,
    locationApproved,
    setLocationApproved,
    triggerGeolocation,
    setTriggerGeolocation,
    user,
  } = useContext(AuthContext)

  const { closeModal } = useModal()

  useEffect(() => {
    if (isLoggedIn && enableHeatMap) {
       Clarity.init('pr4rbb6hep')
       Clarity.identify(`${user.id}`)
    }
  }, [isLoggedIn, enableHeatMap])

  useEffect(() => {
    if (isLoggedIn) {
      if (isiOS()) {
        setPermissionStatus('prompt')

        setTimeout(() => {
          navigator.geolocation.getCurrentPosition(
            () => {
              if (!userDecided && typeof window !== 'undefined') {
                window.dataLayer?.push({
                  event: 'website_consent',
                  consent: 'allow',
                })
              }
              setLocationApproved(true)
              setUserDecided(true)
              setPermissionStatus('granted')
              setPromptVisible(false)
              setTriggerGeolocation(false)
              storageService.setValue('geoGranted', true)
            },
            () => {
              if (!userDecided && typeof window !== 'undefined') {
                window.dataLayer?.push({
                  event: 'website_consent',
                  consent: 'deny',
                })
              }
              setLocationApproved(false)
              setPermissionStatus('denied')
              setPromptVisible(false)
              setUserDecided(true)
              if (storageService.getValue('geoGranted')) {
                storageService.removeValue('geoGranted')
              }
            }
          )
        }, 4000)
      } else {
        const handlePermissionChange = (status) => {
          setPermissionStatus(status.state)
        }

        navigator.permissions
          .query({ name: 'geolocation' })
          .then((permissionStatus) => {
            setPermissionStatus(permissionStatus.state)
            permissionStatus.onchange = () =>
              handlePermissionChange(permissionStatus)
          })
          .catch(() => setPermissionStatus('denied'))
      }
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (
      skipGeolocationApp === null ||
      geolocationInterval === null ||
      permissionStatus === null ||
      !isBrowser()
    ) {
      return
    }

    if (user?.wallet?.active) {
      if (permissionStatus === 'prompt') {
        setTriggerGeolocation(true)
        setUserDecided(false)
      } else {
        setUserDecided(true)
        if (permissionStatus === 'granted') {
          initRadar(true)
        } else {
          initRadar(false)
        }
      }
    }
  }, [
    permissionStatus,
    setTriggerGeolocation,
    skipGeolocationApp,
    geolocationInterval,
  ])

  useEffect(() => {
    if (triggerGeolocation) {
      const timer = setTimeout(checkLocation, 4000)
      return () => clearTimeout(timer)
    }
  }, [triggerGeolocation])

  const checkLocation = () => {
    if (!navigator.geolocation) {
      console.warn(translate('geolocation.geolocationNotSupported'))
      setTriggerGeolocation(false)
      return
    }

    setPromptVisible(true)

    navigator.geolocation.getCurrentPosition(
      () => {
        if (!userDecided && typeof window !== 'undefined') {
          window.dataLayer?.push({
            event: 'website_consent',
            consent: 'allow',
          })
        }
        setLocationApproved(true)
        setUserDecided(true)
        setPermissionStatus('granted')
        setPromptVisible(false)
        setTriggerGeolocation(false)
      },
      () => {
        if (!userDecided && typeof window !== 'undefined') {
          window.dataLayer?.push({
            event: 'website_consent',
            consent: 'deny',
          })
        }
        setLocationApproved(false)
        setPermissionStatus('denied')
        setPromptVisible(false)
        setUserDecided(true)
      }
    )
  }

  const previousPassed = useRef(null) 

  const initRadar = (locationGranted) => {
    Radar.initialize(EnvConfig.GATSBY_RADAR_KEY, {
      logLevel: 'info',
      locationTimeout: 10000,
    })
  
    Radar.setUserId(user.id)
  
    if (locationGranted) {
      Radar.onTokenUpdated((data) => {
        sendUserLocation({ token: data.token }).then((response) => {
          const currentPassed = response.data.passed  
          setRadarResponse(response.data)
          localStorage.setItem('locationStatus', currentPassed)
          if (previousPassed.current !== null && previousPassed.current !== currentPassed) {
            location.reload()
          }
          previousPassed.current = currentPassed
        })
      })
    } else {
      sendUserLocation({ token: '' }).then((response) => {
        const currentPassed = response.data.passed
        localStorage.setItem('locationStatus', currentPassed)
        if (previousPassed.current !== null && previousPassed.current !== currentPassed) {
          location.reload()
        }
        previousPassed.current = currentPassed
      })
    }
  
    const radarSettings = {
      skipVerifyApp: skipGeolocationApp,
      interval: geolocationInterval,
      ipChanges: true,
    }
  
    Radar?.startTrackingVerified(radarSettings)?.catch((err) => console.error('Radar:', err))
  }

  const isOpen =
    triggerGeolocation &&
    !storageService.getValue('geoGranted') &&
    (permissionStatus === 'prompt' || permissionStatus === 'denied')

  useEffect(() => {
    if (isOpen)
      trackEvent(EVENT_NAMES.SCREEN_LOADED, {
        screen_name: EVENT_LOCATIONS.LOCATION_PERMISSION,
      })
  }, [isOpen])

  return (
    <>
      {permissionStatus === 'denied' && isLoggedIn && <TopBannerLocation />}

      <Modal
        theme="payments"
        title={translate('geolocation.locationPermission')}
        onClose={closeModal}
        isOpen={isOpen}
        hideClose
      >
        <StyledWrapper>
          <StyledTitle>{translate('geolocation.turnOnLocation')}</StyledTitle>
          <StyledImage url={locationTrackingIcon}/>

          {!promptVisible && !userDecided && permissionStatus === 'prompt' && (
            <AllowLocation />
          )}

          {promptVisible && !userDecided && permissionStatus === 'prompt' && (
            <WaitingLocation />
          )}

          {(userDecided && !locationApproved) ||
          permissionStatus === 'denied' ? (
            <DeniedLocation />
          ) : null}
        </StyledWrapper>
      </Modal>

      <RadarErrorModal radarResponse={radarResponse}/>
    </>
  )
}

export default GeolocationHandler
