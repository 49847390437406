import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;

  ${themeMedia.mobile`
    padding: 0.5rem 3.5rem 1.5rem 3.5rem;
  `}

  ${themeMedia.desktop`
    padding: 0.5rem 4rem 1.5rem 4rem;
  `};
`

export const AidCustomerContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${themeMedia.mobile`
    width: 80%;
  `}
`

export const AidCustomerTitle = styled.h1`
  white-space: pre-line;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 28.75px;

  ${themeMedia.mobile`
    font-size: 25px;
  `}
`

export const AidCustomerImages = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`

export const AidCharacterImg = styled.img`
  width: 85px;
  ${themeMedia.mobile`
    width: 110px;
  `}
`

export const AidCustomerDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  ${themeMedia.mobile`
    font-size: 16px;
    margin-bottom: 1.5rem;
  `}
`

export const AidCustomerButton = styled.div`
  width: 100%;
  padding: 0.6rem 0 1.5rem 0;
`
