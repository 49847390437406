import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  text-align: left;

  font-size: 14px;
  font-weight: 400;

  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
  }

${themeMedia.desktop`
    margin-top: 50px;
    text-align: center;
    align-items: center;
  `};
`