import React from 'react'
import { useTranslation } from '../../../context/translationProvider'
import { MobileBanner, StyledText } from './style'

export const WaitingLocation = () => {
  const { translate } = useTranslation()

  return (
    <>
      <StyledText style={{ marginTop: '15px' }}>{translate('geolocation.clickAllow')}</StyledText>
      <StyledText style={{ marginTop: '75px' }}>{translate('geolocation.waitingAction')}</StyledText>
      <MobileBanner>
        <StyledText>{translate('geolocation.clickAllowMobile')}</StyledText>
      </MobileBanner>
    </>
  )
}
