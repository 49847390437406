import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledInput,
  StyledInputError,
  StyledInputPrefix,
  StyledInputWrapper,
} from './styles'
import { If } from '../if'
import {
  formatCurrency,
  getFloatNumberFromInputEvent,
} from '../../../utils/currencyFormatting'

const ValueInput = ({
  defaultValue,
  disabled,
  forceValue,
  onChange,
  errors,
  placeholder,
  currencySymbol,
  triggerFocus,
}) => {
  const inputRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)
  const [internValue, setInternValue] = useState(defaultValue)
  const valid = !errors && internValue !== null && Number(internValue) !== 0
  const invalid = !!errors && internValue !== null && Number(internValue) !== 0

  const handleOnChange = (e) => {
    const value = getFloatNumberFromInputEvent(e.target?.value)

    if (!value) setInternValue(null)
    onChange?.(value)
  }

  useEffect(() => {
    setInternValue(formatCurrency(forceValue, { useGrouping: false }))
  }, [forceValue])

  useEffect(() => {
    if (triggerFocus && inputRef.current) {
      inputRef.current.focus()
      setIsFocused(true)
    }
  }, [triggerFocus])

  return (
    <>
      <StyledInputWrapper
        valid={valid}
        invalid={invalid}
        disabled={disabled}
        className={isFocused ? 'focus' : ''}
        onClick={() => inputRef.current.focus()}
      >
        <StyledInputPrefix onClick={() => inputRef.current.focus()}>
          {currencySymbol}
        </StyledInputPrefix>

        <StyledInput
          ref={inputRef}
          onChange={handleOnChange}
          value={internValue}
          placeholder={placeholder}
          id="deposit-input"
          inputmode="numeric"
          min="0"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </StyledInputWrapper>
      <If
        condition={invalid}
        render={() => (
          <StyledInputError id="deposit-error">
            {errors?.message}
          </StyledInputError>
        )}
      />
    </>
  )
}

ValueInput.defaultProps = {
  defaultValue: null,
  disabled: false,
  valid: false,
  invalid: false,
  formProperties: {},
  onChange: () => {},
  mask: null,
  forceValue: null,
  currencySymbol: 'R$',
  triggerFocus: false,
}

ValueInput.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  formProperties: PropTypes.object,
  onChange: PropTypes.func,
  mask: PropTypes.string,
  forceValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currencySymbol: PropTypes.string,
  triggerFocus: PropTypes.bool,
}

export { ValueInput }
