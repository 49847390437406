import React, { useContext } from 'react'

import { useTranslation } from '../../../context/translationProvider'
import { AuthContext } from '../../../context/authProvider'
import { useRealityCheckContext } from '../../../context/realityCheckProvider'

import { ButtonsWrapper, ContentWrapper, Subtitle, Text, Title } from './styles'
import { Modal } from '../../atoms/modal'
import { Button } from '../../atoms/button'
import { Image } from '../../atoms/image'

import RealityCheckClock from '../../../images/reality_check_clock.svg'
import { getMinutesOrHoursFromSeconds, getMinutesAndHoursFromSeconds } from '../../../utils/datetime'
import useModal from '../../../hooks/useModal'
import storageService from '../../../services/storageService'

const RealityCheckModal = () => {
  const { translate } = useTranslation()
  const { isLoggedIn } = useContext(AuthContext)
  const { closeModal } = useModal()
  const { isModalOpen, handleKeepMePlaying, handleLogMeOut, userSetting } =
    useRealityCheckContext()

  if (!isModalOpen || !isLoggedIn) return <></>

  const addTimeToMessage = (message) => {
    const modifiedMessage = message.replace(
      '{time}',
      getMinutesOrHoursFromSeconds(
        userSetting?.duration,
        translate('account.limits.minutes'),
        translate('account.limits.hours')
      )
    )

    return modifiedMessage
  }

  const addActiveDurationToMessage = (message) => {
    const storedStartTime = storageService.getValue('sessionStartTime')    
    const currentTimestamp = Date.now()
    const activeDurationInSeconds = Math.floor((currentTimestamp - storedStartTime) / 1000)
    const modifiedMessage = message.replace(
      '{time}',
      getMinutesAndHoursFromSeconds(
        activeDurationInSeconds,
        translate('account.limits.minutes'),
        translate('account.limits.hours')
      )
    )

    return modifiedMessage
  }

  return (
    <Modal
      theme="payments"
      hideClose
      title={translate('account.limits.realityCheck')}
      onClose={closeModal}
      isOpen={isModalOpen}
    >
      <ContentWrapper>
        <Subtitle>
          {addTimeToMessage(
            translate('account.limits.realityCheckModalSubTitle')
          )}
        </Subtitle>
        <Image url={RealityCheckClock} />
        <Text>
          {addActiveDurationToMessage(translate('account.limits.realityCheckModalText'))}
        </Text>
        <ButtonsWrapper>
          <Button onClick={handleKeepMePlaying} expand>
            {translate('account.limits.realityCheckModalButton1')}
          </Button>

          <Button
            onClick={handleLogMeOut}
            expand
            backgroundColor="white"
            bordered
            textColor="black"
            outlineColor="black"
          >
            {translate('account.limits.realityCheckModalButton2')}
          </Button>
        </ButtonsWrapper>
      </ContentWrapper>
    </Modal>
  )
}

export default RealityCheckModal
