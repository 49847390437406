import React from 'react'
import PropTypes from 'prop-types'
import { FieldWrapper } from '../fieldWrapper'
import {
  StyledDropdownOptionsWrapper,
  StyledInnerWrapper,
  StyledInput,
  StyledLoadingWrapper,
  StyledSuggestionHint,
  StyledSuggestionsWrapper,
} from './styles'
import { If } from '../../atoms/if'
import { Spinner } from '../../atoms/spinner'
import { Text } from '../../atoms/text'
import { themeStyles } from '../../../styles/theme'
import useDeviceDetect from '../../../hooks/useDeviceDetect'

const FieldWithSuggestions = (props) => {
  const {
    label,
    type,
    fieldValue,
    description,
    disabled,
    showErrorMsg,
    autoCompleteOff,
    errors,
    formProperties,
    hasBeenSubmitted,
    onChange,
    renderError,
    isLoading,
    showDropdownIcon,
    suggestions,
    suggestionHint,
    placeholder,
    defaultValue,
  } = props
  const { isMobile } = useDeviceDetect()

  function handleAutoComplete() {
    if (autoCompleteOff == null) return null
    return autoCompleteOff ? 'off' : 'on'
  }

  if (!formProperties) return <></>
  const autoComplete = handleAutoComplete()

  const createFocusedDropdown = () => {
    if (isLoading) {
      return (
        <StyledLoadingWrapper>
          <Spinner
            color={themeStyles.colors.accentColors.primary.main}
            size={'1.5em'}
          />
        </StyledLoadingWrapper>
      )
    }
    if (suggestions?.length > 0) {
      return (
        <StyledDropdownOptionsWrapper>
          <If
            condition={suggestionHint}
            render={() => (
              <StyledSuggestionHint>
                <Text small>{suggestionHint}</Text>
              </StyledSuggestionHint>
            )}
          />
          <StyledSuggestionsWrapper>{suggestions}</StyledSuggestionsWrapper>
        </StyledDropdownOptionsWrapper>
      )
    }
  }

  return (
    <FieldWrapper
      label={label}
      fieldValue={fieldValue}
      description={description}
      showErrorMsg={showErrorMsg}
      errors={errors}
      formProperties={formProperties}
      hasBeenSubmitted={hasBeenSubmitted}
      onChange={onChange}
      renderError={renderError}
      key={`select-${formProperties.name}`}
      scrollOnFocus={isMobile}
      renderInputField={({
        onChangeCombined,
        onFocusCombined,
        onBlurCombined,
        isInFocus,
        invalid,
        data,
      }) => (
        <StyledInnerWrapper>
          <StyledInput
            {...formProperties}
            defaultValue={defaultValue}
            id={formProperties.name}
            type={type}
            autoComplete={autoComplete}
            disabled={disabled}
            onChange={onChangeCombined}
            onFocus={onFocusCombined}
            onBlur={onBlurCombined}
            valid={data != ''}
            invalid={invalid}
            value={fieldValue}
            placeholder={placeholder}
            showDropdownIcon={showDropdownIcon}
          />
          <label name={formProperties.name}>
            <If condition={isInFocus} render={createFocusedDropdown} />
          </label>
        </StyledInnerWrapper>
      )}
    />
  )
}

FieldWithSuggestions.defaultProps = {
  label: null,
  type: null,
  description: null,
  fieldValue: null,
  disabled: null,
  showErrorMsg: null,
  hasBeenSubmitted: null,
  autoCompleteOff: 'on',
  removeFirstOption: false,
  isLoading: false,
  showDropdownIcon: false,
  errors: {},
  formProperties: {},
  onChange: () => {},
  onValueSelected: () => {},
  renderError: null,
  suggestions: [],
  suggestionsHint: null,
  placeholder: ' ',
  defaultValue: '',
}

FieldWithSuggestions.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  fieldValue: PropTypes.string,
  disabled: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
  hasBeenSubmitted: PropTypes.bool,
  autoCompleteOff: PropTypes.bool,
  isLoading: PropTypes.bool,
  showDropdownIcon: PropTypes.bool,
  errors: PropTypes.object,
  formProperties: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onValueSelected: PropTypes.func,
  renderError: PropTypes.func,
  suggestions: PropTypes.array,
  suggestionHint: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
}

export { FieldWithSuggestions }
