import React from 'react'
import { StyledContainer, StyledContent } from './styles'
import InfoIcon from '../../../images/icon_info.svg'
import { Text } from '../../atoms/text'
import PropTypes from 'prop-types'

export const InfoBox = ({
  title,
  content,
}) => {
  return (
    <StyledContainer>
      <img src={InfoIcon} width={20} height={20} />
      <StyledContent>
        <Text 
          bold 
          noMargin
          style={{ fontSize: 14 }}
        >
          {title}
        </Text>
        <div 
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </StyledContent>
    </StyledContainer>
  )
}

InfoBox.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}
