import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  gap: 15px;
  ${themeMedia.mobile`
     padding: 10px 1.5px 10px 15.5px;
  `}
`

export const StyledBasicAlertImage = styled.img``

export const StyledBasicAlertText = styled.div`
  font-size: 14px;
  text-align: left;
`
