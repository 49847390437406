import styled from 'styled-components';

export const StyledModalContentWrapper = styled.div`
  padding: 1rem;
  height: 100%;
`

export const StyledFirstStepContainer = styled.div`
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 54px;
  height: calc(100% - 50px - 1rem);
  padding-top: 50px;
  padding-bottom: 1rem;
`;

export const StyledFirstStepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
`;

export const StyledVerificationMessage = styled.div`
  font-size: 1rem;
  text-align: center;
`

export const StyledVerificationStatusTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
`
