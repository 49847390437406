import AidFace from '../../../images/aid-customer/aid_character_face.svg'
import AidCorrect from '../../../images/aid-customer/aid_character_correct.svg'
import AidProblem from '../../../images/aid-customer/aid_character_problem.svg'
import AidGlass from '../../../images/aid-customer/aid_character_glass.svg'
import AidHat from '../../../images/aid-customer/aid_character_hat.svg'

export const getAidCustomerSteps = (translate) => [
  {
    title: translate('aidCustomer.problemsWithLiveness.title'),
    description: translate('aidCustomer.problemsWithLiveness.description'),
    buttonText: translate('aidCustomer.problemsWithLiveness.buttonText'),
    images: [AidFace],
  },
  {
    title: translate('aidCustomer.lighting.title'),
    description: translate('aidCustomer.lighting.description'),
    buttonText: translate('aidCustomer.lighting.buttonText'),
    images: [AidCorrect, AidProblem],
  },
  {
    title: translate('aidCustomer.faceCovering.title'),
    description: translate('aidCustomer.faceCovering.description'),
    buttonText: translate('aidCustomer.faceCovering.buttonText'),
    images: [AidCorrect, AidGlass, AidHat],
  },
]
