import React from 'react'
import { StyledCircle, StyledCircleStepContainer } from './style'
import PropTypes from 'prop-types'

const SmallCircleSteps = ({ currentStep = 1, totalSteps = 3 }) => {
  return (
    <StyledCircleStepContainer>
      {[...Array(totalSteps)].map((_, index) => (
        <StyledCircle key={index} active={index + 1 === currentStep} />
      ))}
    </StyledCircleStepContainer>
  )
}

SmallCircleSteps.propTypes = {
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
}

SmallCircleSteps.defaultProps = {
  currentStep: 1,
  totalSteps: 3,
}

export default SmallCircleSteps
