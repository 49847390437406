import styled from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const StyledWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${({ $isChecked }) =>
    $isChecked
      ? themeStyles.colors.accentColors.primary.main
      : themeStyles.colors.lightGreys[1].main};

  height: 56px;
  border-radius: 6px;
  padding: 10px 12px;
  box-sizing: border-box;
  gap: 15px;
`

export const StyledContent = styled.div`
  p {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin: 0;
  }
`

export const StyledIconWrapper = styled.div`
  min-width: 40px;

  img {
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px;
    object-fit: contain;
    border-radius: 50%;
  }
`
