import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

const grey = '#dbdbdb'

export const StyledCardWrapper = styled.section`
  width: 100%;
  max-width: 595px;
`

export const FormStyleWrapper = styled.div`
  width: 100%;

  form {
    > div.row-fields:first-of-type {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;

      ${themeMedia.desktop`
          grid-template-columns: 1fr 2fr;
          gap: 18px;
      `}
    }

    > div.row-fields:nth-of-type(2) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;

      ${themeMedia.desktop`
          gap: 18px;
      `}
    }
  }
`

export const StyledWrapperSelectedBank = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin: 0 0 30px 0;

  ${themeMedia.desktop`
      margin: 3.1rem 0 1.8rem 0;
  `}
`

export const StyledSelectBankHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;

  p {
    font-size: 17px;
    font-weight: 400;
    margin: 0;
  }

  button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    background: none;
    border: none;
    text-decoration-line: underline;
    text-decoration-style: solid;
  }
`

export const StyledDisplaySelectedBank = styled.div`
  display: flex;
  align-items: center;

  padding: 20px 0;

  border-width: 1px 0;
  border-color: ${grey};
  border-style: solid;

  img {
    margin-right: 1.25rem;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
`

export const StyledFormHeader = styled.h3`
  font-size: 14px;
  font-weight: 400;
  text-align: left;

  align-self: flex-start;
`