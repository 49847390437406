import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Container, Header, Content, StyledTimerContainer } from './styles'
import { Timer } from '../../atoms/timer'
import { FormatAmount } from '../../atoms/formatAmount'
import { Image } from '../../atoms/image'
import { Text, Title } from '../../atoms/text'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import dayjs from 'dayjs'
import { useTranslation } from '../../../context/translationProvider'

export const CashbackCard = ({
  type,
  icon,
  value,
  title,
  backgroundImage,
  backgroundColor,
  color,
  endDate,
  claimDate,
  currency,
  currencySymbol,
  expiryReminder,
}) => {
  const { isMobile } = useDeviceDetect()
  const { translate } = useTranslation()
  const [isExpiring, setIsExpiring] = useState(false)

  const getLargeAmountStyle = () => {
    if (!isMobile) return

    return {
      fontSize: 40,
    }
  }

  switch (type) {
    case 'large':
      return (
        <Container
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          color={color}
        >
          <Header iconHeader>
            <Image url={icon} width={isMobile ? 40 : 60} />
            <Title
              extraBig
              black
              noMargin
              tagType="span"
              level={1}
              style={getLargeAmountStyle()}
            >
              <FormatAmount
                amount={value}
                shortCode={currency}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                currencySymbol={currencySymbol}
              />
            </Title>
          </Header>
          <Content>
            <StyledTimerContainer $paddingBottom={isExpiring}>
              <Title
                level={3}
                style={{
                  textAlign: 'left',
                  marginBottom: '0.5em',
                  marginTop: isExpiring ? '-0.25em' : 0,
                  fontWeight: 700,
                  fontSize: isMobile ? 20 : 22,
                }}
              >
                {title}
              </Title>
              <Text
                small
                noMargin
                style={{
                  fontSize: isMobile ? 12 : 16,
                }}
              >
                {translate('vault.activeForMessage')}
              </Text>
              <Timer
                endDate={new Date(endDate)}
                color={color}
                expiryReminder={expiryReminder}
                setIsExpiring={setIsExpiring}
                $small
              />
            </StyledTimerContainer>
          </Content>
        </Container>
      )

    case 'small':
    default:
      return (
        <Container
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
          color={color}
          isSmall
        >
          <Header>
            <Title
              level={3}
              style={{
                fontWeight: 700,
                fontSize: isMobile ? 16 : 22,
              }}
            >
              {title}
            </Title>
            <Title
              level={3}
              style={{
                fontWeight: 700,
                fontSize: isMobile ? 16 : 22,
              }}
            >
              <FormatAmount
                amount={value}
                shortCode={currency}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
                currencySymbol={currencySymbol}
              />
            </Title>
          </Header>
          <Content>
            <Text
              small
              noMargin
              style={{
                fontSize: isMobile ? 12 : 16,
              }}
            >
              {`${translate('vault.claimedOn')} ${dayjs(claimDate).format(
                'DD/MM/YYYY'
              )}`}
            </Text>
          </Content>
        </Container>
      )
  }
}

CashbackCard.defaultProps = {
  type: '',
  icon: '',
  value: '',
  title: '',
  backgroundImage: '',
  backgroundColor: '',
  color: '',
  endDate: null,
  claimDate: '',
  currency: 'BRL',
}

CashbackCard.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  endDate: PropTypes.string,
  currency: PropTypes.string,
  claimDate: PropTypes.string,
  expiryReminder: PropTypes.number,
  currencySymbol: PropTypes.string,
}
