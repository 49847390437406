import { useState, useEffect } from 'react'
import { AID_MODAL_TRIGGERS } from '../utils/constants'

export const useAidCustomer = () => {
  const [attemptsFailedCount, setAttemptsFailedCount] = useState(0)
  const [aidWizards, setAidWizards] = useState({
    aidCustomer: false,
    aidSuccess: false,
    aidProblem: false,
  })

  useEffect(() => {
    if (attemptsFailedCount === AID_MODAL_TRIGGERS.AID_CUSTOMER.attempts) {
      setAidWizards((prev) => ({ ...prev, aidCustomer: true }))
    }
    if (attemptsFailedCount === AID_MODAL_TRIGGERS.AID_PROBLEM.attempts) {
      setAidWizards((prev) => ({ ...prev, aidProblem: true }))
    }
  }, [attemptsFailedCount])

  const toggleWizard = (modalKey, value) => {
    setAidWizards((prev) => ({ ...prev, [modalKey]: value }))
  }

  const handleAttemptsFailedCount = () => {
    setAttemptsFailedCount((prev) => prev + 1)
  }

  return {
    aidWizards,
    attemptsFailedCount,
    toggleWizard,
    handleAttemptsFailedCount,
  }
}
