import React from 'react'
import PropTypes from 'prop-types'
import { StyledDefaultBankAccountButton } from './styles'
import { useTranslation } from '../../../context/translationProvider'

export const DefaultBankAccountButton = ({ onClick }) => {
  const { translate } = useTranslation()

  return (
    <StyledDefaultBankAccountButton type="button" onClick={onClick}>
      {translate('payments.bankAccountKeys.updateModal.setDefault')}
    </StyledDefaultBankAccountButton>
  )
}

DefaultBankAccountButton.propTypes = {
  onClick: PropTypes.func,
}
