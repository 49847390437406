import styled, { keyframes } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const SimplePopUpWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999;
  animation: ${fadeIn} 0.3s ease-out;
`

export const SimplePopUpOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
`

export const SimplePopUpContent = styled.div`
  position: relative;
  background: ${themeStyles.colors.white.main};
  width: 90%;
  min-width: 320px;
  max-width: 80%;
  max-height: ${({ mobileHeight }) => `${mobileHeight - 40}px`};
  color: ${themeStyles.colors.black.main};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

  ${themeMedia.tablet`
    max-width: ${({ popUpMinimunWidth }) => `${popUpMinimunWidth}px`};
  `}
`

export const SimplePopUpHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: sticky;
  top: 0;
  z-index: 1;
`

export const SimplePopUpClose = styled.img`
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;

  ${themeMedia.mobile`
     margin-right: 16px;
     margin-top: 16px;
  `}
`
