import React, { Suspense, lazy, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AuthContext } from '../../../context/authProvider.js'

const COMPONENT_PATHS = {
  ToastContainer: 'atoms/toastContainer',
  ModalContainer: 'atoms/modalContainer',

  ShowTCModal: 'molecules/showTCModal',
  UserBalance: 'molecules/userBalance',
  SideMenu: 'molecules/sideMenu',
  SideMenuAccount: 'molecules/sideMenuAccount',
  PlayerInbox: 'molecules/playerInbox',
  CpfModal: 'molecules/cpfModal',
  SOWModal: 'molecules/SOWModal',
  RiskMatrixModal: 'molecules/riskMatrixModal',

  Deposit: 'organisms/deposit',
  WithdrawDrawer: 'organisms/WithdrawDrawer',
}

const SuspensfulComponent = (props) => {
  const { isLoggedIn } = useContext(AuthContext)
  const { componentName, childProps, condition } = props
  const [Component, setComponent] = useState(null)

  useEffect(() => {
    const canLoadComponent = condition == null ? isLoggedIn : condition
    if (!canLoadComponent) return setComponent(null)

    const newComponent = lazy(() =>
      import(`../../${COMPONENT_PATHS[componentName]}`).then((module) => {
        return { default: module[componentName] }
      })
    )
    setComponent(newComponent)
  }, [isLoggedIn])

  if (!Component) return <></>

  return (
    <Suspense fallback={<div />}>
      <Component {...childProps} />
    </Suspense>
  )
}

SuspensfulComponent.defaultProps = {
  condition: null,
  childProps: {},
}

SuspensfulComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  condition: PropTypes.bool,
  childProps: PropTypes.object,
}

export { SuspensfulComponent }
