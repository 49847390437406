import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledBottomBar,
  StyledBottomBarAnchor,
  StyledBottomNavButton,
  StyledBottomNavButtonIcon,
  StyledBottomNavButtonLabel,
  StyledBottomNavChangeContextButton,
} from './styles'
import { pushDataLayer } from '../../../utils/pushDataLayer'
import { isBrowser } from '../../../utils/generic'
import useSlugs from '../../../hooks/useSlugs'
import { useLocation } from '@reach/router'
import { isMobileApp } from '../../../utils/mobileAppFunctionality'
import { useGroowthbookLocaleFeature } from '../../../hooks/useGrowthbook'

const CASINO_CONTEXT = 'casino'
const SPORTS_CONTEXT = 'sports'

const BottomNavigation = ({ eventLocation, navData }) => {
  const shouldHideBottomNav = () => {
    const isBottomBarEnabled = useGroowthbookLocaleFeature(
      'fe_igp_bottom_bar_navigation'
    )
    if (!isBottomBarEnabled) return true
    if (!navData) return true
    if (isMobileApp()) return true
    return false
  }

  const { context, nav_items, change_context_icon } = navData

  const location = useLocation()
  const [selectedItem, setSelectedItem] = useState()
  const [sportsSlug, casinoSlug, liveCasinoSlug] = useSlugs([
    'sports',
    'casino',
    'live-casino',
  ])

  useEffect(() => {
    if (!isBrowser()) return
    const selected = findSelectedItem(nav_items, location)
    setSelectedItem(selected)
  }, [location])

  const validateCasinoSlug = ({ pathname, slug, items }) => {
    const IS_CASINO_CONTEXT = context === CASINO_CONTEXT
    const INCLUDES_SLUG = pathname?.includes(`/${slug}/`)
    const IS_PROMO = pathname?.includes('promo')

    if (IS_CASINO_CONTEXT && INCLUDES_SLUG && !IS_PROMO) {
      const selectedItemIndex = items?.findIndex(
        (item) => item.url.replaceAll('/', '') === slug
      )

      return selectedItemIndex
    }

    return false
  }

  const findSelectedItem = (items, currentLocation) => {
    const { pathname, search } = currentLocation

    const findCasinoItem = validateCasinoSlug({
      pathname,
      slug: casinoSlug,
      items,
    })

    if (findCasinoItem !== false) return findCasinoItem

    const findLiveCasinoItem = validateCasinoSlug({
      pathname,
      slug: liveCasinoSlug,
      items,
    })

    if (findLiveCasinoItem !== false) return findLiveCasinoItem

    const pathWithSearch = `${pathname}${search || ''}`
    const selectedItemIndex = items?.findIndex((item) => {
      const linkNoSlashes = item?.url?.replaceAll('/', '')?.toLocaleLowerCase()
      const pathNoSlashes = pathWithSearch
        ?.replaceAll('/', '')
        ?.toLocaleLowerCase()
      return pathNoSlashes === linkNoSlashes
    })
    return selectedItemIndex
  }

  const handleNavItemClick = (eventValue) => {
    pushDataLayer({
      eventName: 'bottom_nav_item-opened',
      data: {
        screen_name: eventLocation,
        item_name: eventValue,
      },
    })
  }

  const onContextChange = () => {
    const eventValue =
      context === SPORTS_CONTEXT ? 'casino_switch' : 'sports_switch'
    pushDataLayer({
      eventName: 'bottom_nav_item-opened',
      data: {
        screen_name: eventLocation,
        item_name: eventValue,
      },
    })
  }

  const changeContextLink = context === SPORTS_CONTEXT ? `/${casinoSlug}/` :`/${sportsSlug}/`

  if (shouldHideBottomNav()) return <></>

  return (
    <>
      <StyledBottomBarAnchor id="bottom-anchor" />
      <StyledBottomBar>
        {nav_items?.map((item, i) => {
          const selected = selectedItem === i
          return (
            <StyledBottomNavButton
              key={`bottom-nav-button-${i}`}
              $selected={selected}
              onClick={() => handleNavItemClick(item?.eventValue)}
              href={item?.url}
            >
              <StyledBottomNavButtonIcon
                src={item?.icon?.url}
                $selected={selected}
              />
              <StyledBottomNavButtonLabel $selected={selected}>
                {item?.label}
              </StyledBottomNavButtonLabel>
            </StyledBottomNavButton>
          )
        })}
        <a href={changeContextLink}>
          <StyledBottomNavChangeContextButton
            src={change_context_icon?.url}
            onClick={onContextChange}
          />
        </a>
        
      </StyledBottomBar>
    </>
  )
}

BottomNavigation.defaultProps = {
  eventLocation: '',
  navData: null,
}

BottomNavigation.propTypes = {
  eventLocation: PropTypes.string,
  navData: PropTypes.object,
}

export { BottomNavigation }
