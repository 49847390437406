import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Text, Title } from '../../atoms/text'
import { Image } from '../../atoms/image'
import {
  StyledChangeValueLink,
  StyledContentWrapper,
  StyledResendButton,
  StyledSpinnerWrapper,
  StyledTextWrapper,
} from './styles'
import { useTimer } from '../../../hooks/useTimer'
import { utcToZonedTime } from 'date-fns-tz'
import { If } from '../../atoms/if'
import { FieldVerificationCode } from '../fieldVerificationCode'
import { Spinner } from '../../atoms/spinner'

const VerifyEvery60SecondsContent = (props) => {
  const {
    onResend,
    icon,
    onInputFieldDone,
    inputFieldNumOfChars,
    fieldLabel,
    ctaText,
    title,
    description,
    didntReceiveText,
    tickingText,
    duration,
    hideInputField = false,
    useResendOnStart,
    changeValueLink,
    onChangeValueClicked,
    onRender,
  } = props
  const [disabled, setDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [when60SecsExpires, setWhen60SecsExpires] = useState(new Date())

  const { seconds } = useTimer({
    endDate: when60SecsExpires,
    onFinish: () => setDisabled(false),
    immediatellySetTime: true,
  })

  const initTime = () => {
    const now = utcToZonedTime(new Date(), 'UTC')
    now.setSeconds(now.getSeconds() + duration)

    setWhen60SecsExpires(now)
  }

  useEffect(() => {
    initTime()
    if (useResendOnStart) {
      onResend()
    }
  }, [])

  useEffect(() => {
    if (onRender) onRender()
  }, [])

  const onCtaClick = () => {
    onResend()
    initTime()
    setDisabled(true)
  }

  const onInputChanged = async (verification_code) => {
    if (verification_code.length >= inputFieldNumOfChars) {
      setLoading(true)
      const response = await onInputFieldDone({ verification_code })

      if (response?.ok) {
        return
      }

      setLoading(false)
    }
  }

  return (
    <StyledContentWrapper>
      {title && <Title level={2}>{title}</Title>}
      <Image url={icon} />
      <Text textAlign="center">
        <span dangerouslySetInnerHTML={{ __html: description }} />{' '}
        <If
          condition={changeValueLink}
          render={() => (
            <StyledChangeValueLink onClick={onChangeValueClicked}>
              {changeValueLink}
            </StyledChangeValueLink>
          )}
        />
      </Text>
      <If
        condition={onInputChanged && !hideInputField}
        render={() => {
          if (loading)
            return (
              <StyledSpinnerWrapper>
                <Spinner size={'1.5em'} />
              </StyledSpinnerWrapper>
            )

          return (
            <FieldVerificationCode
              label={fieldLabel}
              onChange={onInputChanged}
              length={inputFieldNumOfChars}
            />
          )
        }}
      />

      <StyledTextWrapper>
        <Text>
          <span
            dangerouslySetInnerHTML={{
              __html: didntReceiveText,
            }}
          />
          <If
            condition={disabled}
            render={() => (
              <span
                dangerouslySetInnerHTML={{
                  __html: tickingText?.replace(
                    '{time}',
                    seconds + 1 || duration
                  ),
                }}
              />
            )}
            renderElse={() => (
              <StyledResendButton
                id="resend"
                expand
                disabled={disabled || loading}
                onClick={onCtaClick}
              >
                {ctaText}
              </StyledResendButton>
            )}
          />
        </Text>
      </StyledTextWrapper>
    </StyledContentWrapper>
  )
}

VerifyEvery60SecondsContent.defaultProps = {
  duration: 60,
  inputFieldNumOfChars: 6,
}

VerifyEvery60SecondsContent.propTypes = {
  onResend: PropTypes.func,
  icon: PropTypes.any,
  onInputFieldDone: PropTypes.func,
  inputFieldNumOfChars: PropTypes.number,
  fieldLabel: PropTypes.string,
  ctaText: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  tickingText: PropTypes.string, //! Must add a string with {time} if you want a countdown
  didntReceiveText: PropTypes.string,
  duration: PropTypes.number,
  useResendOnStart: PropTypes.bool,
  changeValueLink: PropTypes.string,
  onChangeValueClicked: PropTypes.func,
  onRender: PropTypes.func,
}

export { VerifyEvery60SecondsContent }
