import { getPlayerBankAccountStatus } from '../../adapters/payments'
import { toast } from '../../atomic-components'
import { KycStatus } from '../constants'

const getBankAccountStatus = ({
  hasAccountsWaitingLiveness,
  hasAccountsForDeposits,
  hasAccountsForWithdrawals,
}) => {
  return {
    DO_NOT_HAVE_BANK_ACCOUNT:
      !hasAccountsWaitingLiveness &&
      !hasAccountsForDeposits &&
      !hasAccountsForWithdrawals,
    HAVE_BANK_ACCOUNT_PENDING_VALIDATION:
      !hasAccountsForWithdrawals && hasAccountsForDeposits,
    HAVE_BANK_ACCOUNT_PENDING_LIVENESS:
      !hasAccountsForWithdrawals &&
      !hasAccountsForDeposits &&
      hasAccountsWaitingLiveness,
  }
}

export const getPlayerBankAccounts = async () => {
  const response = await getPlayerBankAccountStatus()

  if (!response.ok) return toast.error(response.error.message)

  const { data } = response
  const {
    activeAccounts,
    inactiveAccounts,
    hasAccountsWaitingLiveness,
    hasAccountsForDeposits,
    hasAccountsForWithdrawals,
  } = data

  const {
    DO_NOT_HAVE_BANK_ACCOUNT,
    HAVE_BANK_ACCOUNT_PENDING_VALIDATION,
    HAVE_BANK_ACCOUNT_PENDING_LIVENESS,
  } = getBankAccountStatus({
    hasAccountsWaitingLiveness,
    hasAccountsForDeposits,
    hasAccountsForWithdrawals,
  })

  const usableBankAccounts = [...activeAccounts, ...inactiveAccounts]

  return {
    activeAccounts,
    inactiveAccounts,
    usableBankAccounts,
    DO_NOT_HAVE_BANK_ACCOUNT,
    HAVE_BANK_ACCOUNT_PENDING_VALIDATION,
    HAVE_BANK_ACCOUNT_PENDING_LIVENESS,
  }
}

export const generateFullListWithoutPendingLiveness = ({
  activeAccounts,
  inactiveAccounts,
}) => {
  const accountList = [...activeAccounts, ...inactiveAccounts]

  return accountList.filter(
    ({ status }) => status !== KycStatus.WAITING_LIVENESS
  )
}
