import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { HOUR_IN_SECONDS, MINUTE_IN_SECONDS } from './constants'

export const getRegistrationDates = () => {
  const yearsSpan = 100
  const minAge = 18

  const days = []
  const months = []
  const years = []

  let startYear = 0
  import('dayjs').then(({ default: dayjs }) => {
    startYear = parseInt(
      dayjs(dayjs().subtract(yearsSpan, 'year')).format('YYYY')
    )

    for (let day = 1; day <= 31; day++) {
      days.push({ value: day, label: day })
    }

    // const momentLang = lang === 'no' ? 'nb' : lang
    // const enGbLang = ['en', 'gb', 'ca', 'nz', 'ie']
    // const language = enGbLang.includes(lang) ? 'en-gb' : momentLang
    // const locale = require(`dayjs/locale/${language}`)
    // dayjs.locale(locale)

    const monthsString = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    for (const month in monthsString) {
      // Currently we arent using the actual strings
      months.push({ value: parseInt(month) + 1, label: parseInt(month) + 1 })
    }

    for (let year = 0; year < yearsSpan; year++) {
      const value = startYear + year

      if (value < dayjs().year() - minAge + 1) {
        years.push({ value, label: value })
      }
    }

    years.reverse()
  })

  return {
    days,
    months,
    years,
  }
}

export const getDoubleDigitNumber = (number) => {
  return parseInt(number) < 10 ? number.padStart(2, '0') : number
}

export const getDateStr = (data) => {
  const { year, month, day } = data
  if (!year || !month || !day) return ''

  return `${year}-${getDoubleDigitNumber(month)}-${getDoubleDigitNumber(day)}`
}

export const getFormattedDate = (date, format = 'DD.MM.YYYY HH:mm:ss') => {
  dayjs.extend(utc)
  dayjs.extend(timezone)

  return dayjs(date).utc('z').local().tz(dayjs.tz.guess()).format(format)
}

export const getFormattedDateUTCMinus3 = (
  date,
  format = 'DD.MM.YYYY HH:mm:ss'
) => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  if (date) {
    return dayjs.utc(date).tz('America/Sao_Paulo').format(format)
  } else {
    ;('')
  }
}

export const getMinutesOrHoursFromSeconds = (
  seconds,
  minutesLabel,
  hoursLabel
) => {
  const numberSeconds = parseInt(seconds)
  const isLessThanHour = numberSeconds < HOUR_IN_SECONDS

  if (isLessThanHour) {
    const valueInMinutes = Math.floor(numberSeconds / MINUTE_IN_SECONDS)
    const correctMinutesLabel = minutesLabel.replace('s', '')
    const isExactlyOneMinute = numberSeconds === MINUTE_IN_SECONDS

    return `${valueInMinutes} ${
      isExactlyOneMinute ? correctMinutesLabel : minutesLabel
    }`
  }

  const correctHoursLabel = hoursLabel.replace('s', '')
  const isExactlyOneHour = numberSeconds === HOUR_IN_SECONDS
  const valueInHours = Math.floor(numberSeconds / HOUR_IN_SECONDS)

  return `${valueInHours} ${isExactlyOneHour ? correctHoursLabel : hoursLabel}`
}

export const getMinutesAndHoursFromSeconds = (
  seconds,
  minutesLabel,
  hoursLabel
) => {
  const numberSeconds = parseInt(seconds)
  const isLessThanHour = numberSeconds < HOUR_IN_SECONDS

  if (isLessThanHour) {
    const valueInMinutes = Math.floor(numberSeconds / MINUTE_IN_SECONDS)
    const correctMinutesLabel =
      valueInMinutes === 1 ? minutesLabel.replace('s', '') : minutesLabel
    return `${valueInMinutes} ${correctMinutesLabel}`
  }

  const valueInHours = Math.floor(numberSeconds / HOUR_IN_SECONDS)
  const remainingSeconds = numberSeconds % HOUR_IN_SECONDS
  const valueInMinutes = Math.floor(remainingSeconds / MINUTE_IN_SECONDS)

  const correctHoursLabel =
    valueInHours === 1 ? hoursLabel.replace('s', '') : hoursLabel
  const correctMinutesLabel =
    valueInMinutes === 1 ? minutesLabel.replace('s', '') : minutesLabel

  return `${valueInHours} ${correctHoursLabel} ${valueInMinutes} ${correctMinutesLabel}`
}

export const hoursToMinutes = (hours) => hours * 60
export const daysToHours = (days) => days * 24
