import styled, { css, keyframes } from 'styled-components'
import preloader from '../../../images/preloader.gif'
import { themeStyles } from '../../../styles/theme'

export const Loader = styled.div`
  height: 100vh;
  width: 100%;
  background: url('${preloader}') no-repeat center 30%;
  background-size: 50px;
`

const l20_1 = keyframes`
  0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
  12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
  25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
  50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
  75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
  100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
`

const l20_2 = keyframes`
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
`

export const SpinnerLoader = styled.div`
  width: 36px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid ${themeStyles.colors.primary.main};
  animation:
    ${l20_1} 1s infinite linear alternate,
    ${l20_2} 2s infinite linear;

  ${({ theme }) => {
      if (theme === 'accentPrimary') {
        return css`
          border-color: ${themeStyles.colors.accentColors.primary.main};
        `
      }
      
      if (theme === 'accentSecondary') {
        return css`
          border-color: ${themeStyles.colors.accentColors.secondary.main};
        `
      }

      if (theme === 'accentTertiary') {
        return css`
          border-color: ${themeStyles.colors.accentColors.tertiary.main};
        `
      }

  }}  

  ${({ size }) => {
    if (size === 'sm') {
      return css`
        width: 18px;
      `
    }

    if (size === 'md') {
      return css`
        width: 36px;
      `
    }

    if (size === 'lg') {
      return css`
        width: 72px;
      `
    }
  }}
`
