import React from 'react'
import PropTypes from 'prop-types'
import { BackButton } from '../backButton'
import { If } from '../if'
import {
  StyledProgressBarStep,
  StyledOuterWrapper,
  StyledStepsWrapper,
  StyledBackButtonWrapper,
} from './styles'
import { useTranslation } from '../../../context/translationProvider'

const ProgressBar = (props) => {
  const {
    formStep,
    steps,
    goBack,
    customLeftButtonPosition,
    REGISTRATION_STEPS 
  } = props
  const {
    progress,
    hideBackButton,
    hideProgress
  } = REGISTRATION_STEPS[formStep] ?? {} 
  
  const { translate } = useTranslation()

  if (hideProgress) return <></>

  if (progress > steps.length) return <></>
  return (
    <StyledOuterWrapper>
      <StyledBackButtonWrapper>
        <If
          condition={!hideBackButton}
          render={() => (
            <BackButton
              whiteBackground
              onClick={goBack}
              left={customLeftButtonPosition ? customLeftButtonPosition : '0'}
            />
          )}
        />
      </StyledBackButtonWrapper>
      <StyledStepsWrapper>
        {steps.map((step, i) => {
          const index = i + 1
          return (
            <StyledProgressBarStep
              key={`progress-step-${index}`}
              isDone={progress > index}
              isActive={progress == index}
              icon={step.icon}
              text={translate(step.text)}
            >
              {index}
            </StyledProgressBarStep>
          )
        })}
      </StyledStepsWrapper>
    </StyledOuterWrapper>
  )
}

ProgressBar.propTypes = {
  formStep: PropTypes.number,
  steps: PropTypes.array,
  goBack: PropTypes.func,
  useOldFlow: PropTypes.bool,
  customLeftButtonPosition: PropTypes.string,
  REGISTRATION_STEPS: PropTypes.array,
}

export { ProgressBar }
